import React, { useRef, useState } from "react"
import { Container, Form, Row } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BASE_URL } from "../BASE_URL"
import { useEffect } from "react"
import axios from "axios"
import data from ".././CountryStateCity.json"
import loader from "../images/loading.png"
import Footer from "./Footer"
import Header from "./Header"

function Signup(props) {

  const [loading, setLoading] = useState(false)
  const [verifyLoading, setVerifyLoading] = useState(false)

  const [show, setShow] = useState(false)

  const countries = data.find((obj) => obj.name === "India")

  // Log State Array
  if (countries && countries.states) {
    countries.states.forEach((state) => {
      if (state.cities) {
        state.cities.forEach((city) => { })
      }
    })
  }

  const navigate = useNavigate()

  const [show1, setShow1] = useState(false)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const [numbersave, setNumbersave] = useState()

  const [show3, setShow3] = useState(false)

  const handleClose3 = () => setShow3(false)

  const [show4, setShow4] = useState(false)

  const handleClose4 = () => setShow4(false)

  const [newPasswordVisible, setNewPasswordVisible] = useState(false)

  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] =
    useState(false)

  const toggleNewPasswordVisibility = () => {
    setNewPasswordVisible(!newPasswordVisible)
  }

  const toggleConfirmNewPasswordVisibility = () => {
    setConfirmNewPasswordVisible(!confirmNewPasswordVisible)
  }

  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }

  const [newpassword, setNewpassword] = useState()

  const handleNewpassword = (e) => {
    const value = e.target.value
    setNewpassword(value)
  }

  const [confirmNewpassword, setConfirmNewpassword] = useState()

  const handleConfirmNewpassword = (e) => {
    const value = e.target.value
    setConfirmNewpassword(value)
  }

  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [nameError, setNameError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [stateError, setStateError] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [confirmPasswordError, setConfirmPasswordError] = useState("")
  const [cityError, setCityError] = useState("")
  const [genderError, setGenderError] = useState("")
  const [checkError, setCheckError] = useState("")
  const [selectedState, setSelectedState] = useState("")
  const [selectedCity, setSelectedCity] = useState("")

  const [selectedGender, setSelectedGender] = useState("")

  const [registerData, setRegisterData] = useState({
    name: "",
    phone: "",
    state: "",
    email_address: "",
    city: "",
    gender: "",
  })

  const handleNameChange = (e) => {
    setRegisterData({ ...registerData, name: e.target.value })
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === "name" && (value.trim() === "" || value === "")) {
      setNameError("Enter your name")
      return
    }

    if (name === "state") {
      setSelectedState(value)
      setSelectedCity("")
    }

    if (name === "city") {
      setSelectedCity(value)
    }

    if (name === "gender") {
      setSelectedGender(e.target.value)
    }

    setRegisterData({ ...registerData, [name]: e.target.value.trim() })
  }

  const [myPhone, setMyPhone] = useState("")
  const [number, setNumber] = useState("")
  const [otpValidate, setOtpValidate] = useState("")

  const [myUsers, setMyUsers] = useState("")

  const [inputValues, setInputValues] = useState('');
  const [sentOtp, setsentOtp] = useState('');
  const inputRefs = useRef(Array.from({ length: 6 }, () => null));

  const createRef = (index) => (ref) => {
    inputRefs.current[index] = ref;
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;

    if (/^\d$/.test(value)) {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      e.target.value = '';
    }

    const updatedValues = inputRefs.current.map((ref) => ref.value).join('');
    setInputValues(updatedValues);
  };

  const handleInputKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !e.target.value) {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const renderInputs = () => {
    const inputs = [];

    for (let i = 0; i < 6; i++) {
      inputs.push(
        <input
          key={i}
          type='text'
          maxLength={1}
          pattern="\d*"
          onChange={(e) => handleInputChange(i, e)}
          onKeyDown={(e) => handleInputKeyDown(i, e)}
          ref={createRef(i)}
          style={{
            width: '40px',
            height: '40px',
            margin: '0 5px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            fontSize: '16px',
            padding: '8px',
            textAlign: 'center',
          }}
        />
      );
    }

    return inputs;
  };


  const handleNumberChange = (e) => {
    const { name, value } = e.target
    setMyPhone(e.target.value)
    setRegisterData({ ...registerData, [name]: value.trim() })
  }

  const token = localStorage.getItem("userToken")

  const handleSubmit = async () => {
    if (newpassword.length < 6) {
      toast.error("New password should have 6 characters.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (!newpassword && !confirmNewpassword) {
      toast.error("Please new password and confirm password!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (!newpassword && confirmNewpassword) {
      toast.error("Please enter new password!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (confirmNewpassword && newpassword < 6) {
      toast.error("New password must be more than 5 letters!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (newpassword && !confirmNewpassword) {
      toast.error("Please enter confirm password!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (newpassword !== confirmNewpassword) {
      // alert("password and confirm password is not same");
      toast.error("New password and confirm password is not same!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    const res = await fetch(`${BASE_URL}user/chengepassword`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        phone: Number(numbersave),
        newpassword: newpassword,
      }),
    })
    const data = await res.json()

    if (data.code == 200) {
      // alert("");
      toast.success("Password changed successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setShow4(false)
    } else {
      // setValidDetails("Enter Valid Details");
      // setValidDetails(data.message)
      return
    }
  }


  const crtAccout = async (e) => {
    e.preventDefault()

    const { phone, state, email_address, city, gender, name } = registerData

    if (
      name.length === 0 ||
      phone.length < 10 ||
      state.length === 0 ||
      !/^[a-zA-Z0-9]+([a-zA-Z0-9._%+-]*[a-zA-Z0-9])?@[a-zA-Z0-9]+([\w-]+\.)+[\w-]{2,4}$/.test(
        email_address,
      ) ||
      password.length === 0 ||
      city.length === 0 ||
      selectedGender.length === 0 ||
      isChecked === false
    ) {
      toast.error("Please fill all fields!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      setNameError("Enter Your Name")
      if (phone.length == 0) {
        setPhoneError("Enter Phone Number")
      } else {
        setPhoneError("Enter Valid Phone Number")
      }
      setStateError("Enter Your State")
      setEmailError("Enter Valid Email")
      setPasswordError("Enter Your Password")
      setConfirmPasswordError("Enter Your Confirm Password")
      setCityError("Enter Your City")
      setGenderError("Select Your Gender")
      setCheckError("Please Agree Our Privacy Policy and Terms of services")
      return
    }

    if (name.trim() === "") {
      toast.error("Enter your name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (password.length < 6 && password.length != 0) {
      setPasswordError("Password must be 6 character")
      toast.error("Password must be 6 character!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (confirmPassword !== password) {
      toast.error("Password and confirm password not matched!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    setLoading(true)

    setNumber("+91" + myPhone)

    const number = "+91" + myPhone;
    const random = Math.floor(Math.random() * 900000) + 100000;
    setsentOtp(random)

    const url = `https://rslri.connectbind.com:8443/bulksms/bulksms?username=DG35-webearl&password=digimile&type=0&dlr=1&destination=${number}&source=WEBEAR&message=Dear User, Your one time password is ${random} and it is valid for 10 minutes. Do not share it to anyone. Thank you, Team WEBEARL TECHNOLOGIES.&entityid=1101602010000073269&tempid=1107169899584811565`;

    setLoading(false)
    toast.success('OTP sent successfully!', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
    });
    handleShow1()
    const response = await axios.get(url);
    console.log(response)
  }

  const handlePasswordChange = (event) => {
    const value = event.target.value
    setPassword(value)
  }

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value
    setConfirmPassword(value)
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible)
  }

  // login

  const [user, setUser] = useState({
    phone: "",
  })


  // validation
  const loginHandle = async (e) => {
    e.preventDefault()

    const { phone } = user

    if (phone.length < 10 || password.length == 0) {

      const res = await fetch(`${BASE_URL}user/loginAll`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: Number(phone),
          password,
          role: "customer",
        }),
      })
      const data = await res.json()

      if (data.code === 200) {
        localStorage.setItem("userToken", data.data.token)
        navigate("/")
      } else {
        // setValidDetails("Enter Valid Details")
        return
      }
    }
  }

  useEffect(() => {
    loadCountries()
  }, [])
  useEffect(() => {
    loadStates()
    loadCities()
  }, [])

  const loadCountries = async () => {
    try {
      await axios
        .get("https://www.universal-tutorial.com/api/getaccesstoken", {
          headers: {
            Accept: "application/json",
            "api-token":
              "ftkic-0z5KhVZqsBsoI-tH_7A-1NGcKkOEpGojs1DIKRhL2mHTF1Pdba-oSZMQjDr7E",
            "user-email": "webearlitsolution@gmail.com",
          },
        })
        .then((res) => { })
    } catch (error) { }
  }

  const loadStates = async (country) => {
    await axios
      .get(`https://www.universal-tutorial.com/api/states/${country}`, {
        headers: {
          Accept: "application/json",

          "user-email": "sarjilp2903@gmail.com",
        },
      })
      .then((res) => { })
  }

  const loadCities = async (state) => {
    await axios
      .get(`https://www.universal-tutorial.com/api/cities/${state}`, {
        headers: {
          Accept: "application/json",
          "user-email": "sarjilp2903@gmail.com",
        },
      })
      .then((res) => { })
  }

  const verifyOtp = async () => {

    if (!inputValues) {
      toast.error("Please Enter Otp!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      })
      return;
    }

    if (Number(inputValues).length < 6) {
      toast.error("Please Enter Valid Otp!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      })
      return;
    }

    if (sentOtp === Number(inputValues)) {
      setVerifyLoading(true)
      try {
        toast.success("OTP verified successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })

        setOtpValidate("yes")

        handleClose1()

        const { phone, state, email_address, city, gender, name } = registerData

        const res = await fetch(`${BASE_URL}user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            phone: Number(myPhone),
            state,
            email_address,
            password,
            city,
            gender,
          }),
        })
        const datas = await res.json()

        setVerifyLoading(false)

        if (datas.code == 200) {
          openLoginBox()
          loginHandle()
        }

        if (datas.code == 403) {
          toast.error(`${data.message}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1000,
          })
        }
      } catch (error) {
        setVerifyLoading(false)
        console.error("Error verifying OTP:", error)
        toast.error("Error In Registration!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
      }
    } else {
      setVerifyLoading(false)
      toast.error("Invalid Otp!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      })
    }
  }

  const openLoginBox = () => {
    setShow(true)
  }

  useEffect(() => {
    const token = localStorage.getItem("userToken") || ""
    if(token && token !== ""){
      navigate("/")
    }
  }, [show]);

  return (
    <>
      <Header setShow={setShow} show={show} />
      <section className='py-5'>
        <Container>
          <div className='px-sm-5 px-3'>
            <div className='cmn py-5 px-sm-5 px-4'>
              <div className='text-center'>
                <p className='cmnp text_20'>Sign Up to your account</p>
              </div>
              <div className='py-2'>
                <Form>
                  <Row>
                    {/*------------------------ full name  -----------------------*/}
                    <div className='col-6'>
                      <div className='py-1 pt-2'>
                        <p className='cmnp text-20'>Full Name</p>
                        <Form.Control
                          id='name'
                          name='name'
                          value={registerData.name}
                          onChange={handleNameChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z ]/,
                              "",
                            )
                            handleNameChange(e)
                          }}
                        />
                        {registerData.name.length == 0 ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {nameError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/*------------------------ mobile_number  -----------------------*/}
                    <div className='col-6'>
                      <div className='py-1 pt-2'>
                        <p className='cmnp text-20'>Mobile</p>
                        <div className='d-flex mb-2' style={{ gap: "10px" }}>
                          <Form.Control
                            type='text'
                            name='phone'
                            onChange={handleNumberChange}
                            onInput={(e) => {
                              // Remove non-numeric characters
                              const sanitizedInput = e.target.value.replace(/\D/g, "");
                              // If the input starts with '0', remove it
                              let truncatedInput = sanitizedInput;
                              if (truncatedInput.startsWith('0')) {
                                truncatedInput = truncatedInput.substring(1);
                              }
                              // Set the state only if the input is a valid number
                              if (!isNaN(truncatedInput)) {
                                e.target.value = truncatedInput.slice(0, 10);
                              }
                            }}
                          />

                          {/* <p className='w-25 otp_send_btn' onClick={sendOtp}>
                              SEND OTP
                            </p> */}
                        </div>
                        {registerData.phone.length < 10 ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {phoneError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/*---------------------- email  -------------------------------------*/}
                    <div className='col-6'>
                      <div className='py-1 pt-2'>
                        <p className='cmnp text-20'>Email</p>
                        <Form.Control
                          type='Email'
                          name='email_address'
                          onChange={handleChange}
                        />
                        {registerData.email_address.length == 0 ||
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                            registerData.email_address,
                          ) ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {emailError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/* ----------------------- gender  -----------------------*/}
                    <div className='col-6'>
                      <div className='py-1 pt-2'>
                        <div>
                          <p className='cmnp'>Gender</p>
                        </div>
                        <ToastContainer />
                        <Row>
                          {/*------------------------ gender  -----------------------*/}

                          <div className='col-12 col-lg-6'>
                            <div>
                              <div class='check-box check-box-0 text-center p-0 m-0'>
                                <label
                                    /*for="whyNeed"*/ class='w-100 w-lg-75'
                                >
                                  <input
                                    type='checkbox'
                                    name='gender'
                                    // id="whyNeed"
                                    value='male'
                                    checked={selectedGender === "male"}
                                    onChange={handleChange}
                                  />
                                  <span class='py-2 klass w-100'>Male</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className='col-12 col-lg-6'>
                            <div>
                              <div>
                                <div class='check-box check-box-1  m-0 p-0'>
                                  <label
                                    // for="whyWas"
                                    class='w-100 w-lg-75 text-center'
                                  >
                                    <input
                                      type='checkbox'
                                      name='gender'
                                      // id="whyWas"
                                      value='female'
                                      checked={selectedGender === "female"}
                                      onChange={handleChange}
                                    />
                                    <span class='py-2 klass1 w-100'>
                                      Female
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {registerData.gender.length === 0 ? (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {genderError}
                            </span>
                          ) : (
                            ""
                          )}
                        </Row>
                      </div>
                    </div>

                    {/*------------------------ state  -----------------------*/}
                    <div className='col-6'>
                      <div className='py-1 pt-3'>
                        <p className='cmnp text-20'>State</p>
                        <select
                          onChange={handleChange}
                          value={selectedState}
                          name='state'
                          id=''
                          className='cmnp w-100'
                          style={{
                            padding: ".375rem 0.75rem",
                            borderRadius: "5px",
                          }}
                        >
                          <option value=''>Select Your State</option>
                          {countries.states &&
                            countries.states.map((e) => {
                              return <option value={e.name}>{e.name}</option>
                            })}
                        </select>
                        {registerData.state.length == 0 ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {stateError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/* ------------------------------- city -------------------------------- */}
                    <div className='col-6'>
                      <div className='py-1 pt-3'>
                        <p className='cmnp text-20'>City</p>
                        <select
                          name='city'
                          onChange={handleChange}
                          id=''
                          className='cmnp w-100'
                          style={{
                            padding: ".375rem 0.75rem",
                            borderRadius: "5px",
                          }}
                        >
                          <option value=''>Select Your City</option>
                          {countries.states &&
                            countries.states
                              .filter((state) => state.name === selectedState)
                              .map((state) =>
                                state.cities.map((city) => (
                                  <option key={city.id} value={city.name}>
                                    {city.name}
                                  </option>
                                )),
                              )}
                        </select>
                        {registerData.city.length == 0 ? (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {cityError}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/*------------------ password  --------------------------*/}
                    <div className='col-6 my-4'>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <p className='cmnp text-20'>Password</p>
                        <input
                          type={passwordVisible ? "text" : "password"}
                          name='password'
                          className='py-1 px-3'
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "1px solid #09646d",
                          }}
                          value={password}
                          onChange={(handleChange, handlePasswordChange)}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                              "",
                            )
                          }}
                        />
                        <img
                          src={passwordVisible ? "/eye.png" : "/eye2.png"}
                          className='eye-image'
                          alt=''
                          style={{
                            height: "10px",
                            width: "15px",
                            position: "absolute",
                            top: "20px",
                            bottom: "0",
                            right: "5%",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={togglePasswordVisibility}
                        />
                      </div>
                      {password.length == 0 ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {passwordError}
                        </span>
                      ) : (
                        ""
                      )}
                      {password.length < 6 && password.length != 0 ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {passwordError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                    {/* -------------------------- confirm password  ----------------------------*/}
                    <div className='col-6 my-4'>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <p className='cmnp text-20'>Confirm Password</p>
                        <input
                          type={confirmPasswordVisible ? "text" : "password"}
                          name='password'
                          className='py-1 px-3'
                          style={{
                            width: "100%",
                            borderRadius: "5px",
                            border: "1px solid #09646d",
                          }}
                          value={confirmPassword}
                          onChange={
                            (handleChange, handleConfirmPasswordChange)
                          }
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z0-9!@#$%^&*()_+\-=[\]{}|;:'",.<>?/]/g,
                              "",
                            )
                          }}
                        />
                        <img
                          src={
                            confirmPasswordVisible ? "/eye.png" : "/eye2.png"
                          }
                          className='eye-image'
                          alt=''
                          style={{
                            height: "10px",
                            width: "15px",
                            position: "absolute",
                            top: "20px",
                            bottom: "0",
                            right: "5%",
                            margin: "auto",
                            cursor: "pointer",
                          }}
                          onClick={toggleConfirmPasswordVisibility}
                        />
                      </div>
                      {confirmPassword.length == 0 ? (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {confirmPasswordError}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>

                  </Row>
                  <div className='pt-3 text-center row'>
                    <div className='col-sm-6 col-12 text-sm-end text-center'>
                      <div className='d-flex justify-content-sm-end justify-content-center'>
                        <p
                          className='me-2 cmnpointer cmn cmnnone'
                          style={{
                            width: "140px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src='/google.png'
                            className='pe-1'
                            style={{ height: "25px" }}
                            alt='google'
                          />
                          Google
                        </p>
                      </div>
                    </div>
                    <div className='col-sm-6 col-12 text-sm-start text-center mt-sm-0 mb-3'>
                      <div className='d-flex justify-content-sm-start justify-content-center'>
                        <p
                          className='cmnpointer cmn cmnnone'
                          style={{
                            width: "140px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src='/facebook.png'
                            className='pe-1'
                            style={{ height: "25px" }}
                            alt='facebook'
                          />
                          Facebook
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='py-2 text-center d-flex justify-content-center'>
                    <div>
                      <div className='d-flex'>
                        <input
                          type='checkbox'
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <p className='cmnp rgba ps-1'>
                          I agree to{" "}
                          <a
                            href='/privacy-policy'
                            style={{ color: "#09646d" }}
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            href='/payment-policy'
                            style={{ color: "#09646d" }}
                          >
                            Terms of services
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='text-center d-flex justify-content-center'>
                    {loading ? (
                      <p
                        className='registration_btn mt-3'
                        type='submit'
                      >
                        <img src={loader} alt="" className="spinner" />
                      </p>
                    ) : (
                      <p
                        className='registration_btn mt-3'
                        onClick={crtAccout}
                        type='submit'
                      >
                        Create an account
                      </p>
                    )}
                  </div>
                  <div className='text-center pt-2'>
                    <p className='cmnp rgba'>
                      Already have an account?{" "}
                      <span
                        className='text-dark cmnpointer'
                      >
                        Sign In
                      </span>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* LOgin-page */}

      <div>

        <Modal show={show1} onHide={handleClose1} backdrop="static">
          <Modal.Body>
            <div>
              <Container>
                <div className='common_p pt-3 '>
                  <div className='inerp border-1 pb-0'>
                    <>
                      <div className='col-12'>
                        <form>
                          <div>
                            <h6 style={{ fontWeight: "600", fontSize: "18px", marginBottom: "10px", textAlign: "center" }}>
                              Enter OTP
                            </h6>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {renderInputs()}
                            </div>
                          </div>
                          <div className='d-flex justify-content-center gap-3 mt-3'>
                            <p
                              type='submit'
                              className="registration_otp_close_btn"
                              onClick={handleClose1}
                            >
                              Close
                            </p>
                            {verifyLoading ? (
                              <p
                                type='submit'
                                className="registration_otp_verify_btn"
                              >
                                <img src={loader} alt="" className="spinner" />
                              </p>
                            ) : (
                              <p
                                type='submit'
                                className="registration_otp_verify_btn"
                                onClick={verifyOtp}
                              >
                                Verify
                              </p>
                            )}
                          </div>
                        </form>
                      </div>
                    </>
                  </div>
                </div>
              </Container>
            </div>
          </Modal.Body>
        </Modal>

        <div>
          <Modal show={show3} onHide={handleClose3}>
            <Modal.Body>
              <Container>
                <div>
                  <div className='common p-4 text-center'>
                    <div>
                      <h6
                        className='text-center'
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Enter O.T.P
                      </h6>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {renderInputs()}
                    </div>
                    <div className='pt-3'>
                      <button
                        type='submit'
                        style={{
                          color: "white",
                          background: "#09646D",
                          fontSize: "14px",
                          padding: "8px 30px",
                          border: "none",
                          borderRadius: "8px",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </Container>
            </Modal.Body>
          </Modal>
        </div>

        <div>
          <Modal show={show4} onHide={handleClose4}>
            <Modal.Body>
              <div>
                <div>
                  <div className='py-2 pt-3'>
                    <h6
                      className='text-center'
                      style={{ fontWeight: "600", fontSize: "20px" }}
                    >
                      Set New Password
                    </h6>
                  </div>
                  <div className='text-center py-2'>
                    <p className='cmnp'>New Password</p>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        type={newPasswordVisible ? "text" : "password"}
                        onChange={handleNewpassword}
                        value={newpassword}
                      />
                      <img
                        src={newPasswordVisible ? "/eye.png" : "/eye2.png"}
                        className='eye-image'
                        alt=''
                        style={{
                          height: "10px",
                          width: "15px",
                          position: "absolute",
                          top: "0",
                          bottom: "0",
                          right: "15%",
                          margin: "auto",
                          cursor: "pointer",
                        }}
                        onClick={toggleNewPasswordVisibility}
                      />
                    </div>
                  </div>
                  <div className='text-center py-2'>
                    <p className='cmnp'>Conform New Password</p>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <input
                        type={confirmNewPasswordVisible ? "text" : "password"}
                        onChange={handleConfirmNewpassword}
                        value={confirmNewpassword}
                        minLength={6}
                      />
                      <img
                        src={
                          confirmNewPasswordVisible ? "/eye.png" : "/eye2.png"
                        }
                        className='eye-image'
                        alt=''
                        style={{
                          height: "10px",
                          width: "15px",
                          position: "absolute",
                          top: "0",
                          bottom: "0",
                          right: "15%",
                          margin: "auto",
                          cursor: "pointer",
                        }}
                        onClick={toggleConfirmNewPasswordVisibility}
                      />
                    </div>
                  </div>
                  <div className='pt-5 text-center'>
                    <button
                      type='submit'
                      onClick={handleSubmit}
                      style={{
                        color: "white",
                        background: "#09646D",
                        fontSize: "14px",
                        padding: "8px 30px",
                        border: "none",
                        borderRadius: "8px",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>

        <ToastContainer />
      </div>
      <hr />
      <Footer />
    </>
  )
}

export default Signup
