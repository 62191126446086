import {
  faBus,
  faCheck,
  faIndianRupeeSign,
  faPlane,
  faStar,
  faCalendar,
  faUserDoctor,
  faLocationPinLock,
  faPaperclip,
  faCross,
  faCancel,
  faXmark,
  faBrush,
  faHandshakeAlt,
  faDoorClosed,
  faBowlFood,
  faCookie,
  faMountainCity,
  faCalendarDays,
  faHouseFloodWaterCircleArrowRight
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Form, Modal, Row } from "react-bootstrap"
import Header from "./Header"
import "./css/index1.css"
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-regular-svg-icons"
import { useState, useEffect } from "react"
import { useLocation, useParams } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import { faTrainSubway } from "@fortawesome/free-solid-svg-icons"
import { faBinoculars } from "@fortawesome/free-solid-svg-icons"
import { faHotel } from "@fortawesome/free-solid-svg-icons"
import { faUtensils } from "@fortawesome/free-solid-svg-icons"
import { faTaxi } from "@fortawesome/free-solid-svg-icons"
import { useRef } from "react"
import Uppage from "./Uppage"
import Privacy from "../Privacy"
import Footer from "./Footer"
import Section_modal from "./Section_modal"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useNavigate } from "react-router-dom"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import countries from "../CountryStateCity.json"
import axios from "axios"

const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow.toISOString().split('T')[0];
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

function Packega_details(props) {

  const [cities, setCities] = useState([])

  const selectedStates = countries?.find((e) => e?.name === "India")

  const overRef = useRef(null);
  const services = useRef(null);
  const itenarary = useRef(null);
  const hotels = useRef(null);
  const privacy = useRef(null);


  const scrollTo = (section) => {
    let targetRef

    switch (section) {
      case "overview":
        targetRef = overRef
        break
      case "services":
        targetRef = services
        break
      case "itenarary":
        targetRef = itenarary
        break
      case "hotels":
        targetRef = hotels
        break
      case "privacy":
        targetRef = privacy
        break
      default:
        targetRef = null
    }

    if (targetRef) {
      window.scroll({
        top: targetRef.current.offsetTop,
        behavior: "smooth",
      })
    }
  }

  const [show, setShow] = useState(false)
  const navigate = useNavigate();

  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)


  const [categoty, setCategoty] = useState([])

  const getCategoty = async () => {
    const res = await fetch(`${BASE_URL}destinationcategory`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setCategoty(data.data)
  }



  const { id, merchentId } = useParams();

  const [pDetails, setPDetails] = useState([])
  const [pricePerPerson, setPricePerPerson] = useState([])
  console.log(pricePerPerson)
  const [pricePercentage, setPricePercentage] = useState([])

  const [hotel_itienrary, setHotel_itienrary] = useState([])
  const [availableDate, setAvailableDate] = useState("")

  const handleCheckAvailability = (e) => {
    setAvailableDate(e.target.value)
  }

  const [displayBook, setDisplayBook] = useState(false)
  const [errorMessageShow, setErrorMessageShow] = useState(false);

  const handleCheck = () => {

    const token = localStorage.getItem("userToken")

    if (!availableDate) {
      toast.error("Please Select Date!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    } else {
      if (!token) {
        alert("please Login First")
      } else {
        const dateToUse = availableDate ? new Date(availableDate) : new Date();
        const startDate = new Date(formattedStartDate);
        const endDate = new Date(formattedEndDate);
        console.log(dateToUse)

        if (availableDate && dateToUse > endDate) {
          setErrorMessageShow(true);
          setDisplayBook(false);
          return;
        }
        setDisplayBook(true);
        setErrorMessageShow(false);

        const matchingPriceObject = pDetails?.[0]?.price_and_date.find((item) => {
          const startDate = new Date(item.price_start_date);
          const endDate = new Date(item.price_end_date);
          const date = new Date(dateToUse);
          const month = date.getMonth() + 1;
          const startMonth = startDate.getMonth() + 1;

          // Check if the month matches
          if (month === startMonth) {
            console.log("matched");

            // Check if the date falls within the range
            if (dateToUse.getDate() >= startDate.getDate() && dateToUse.getDate() <= endDate.getDate()) {
              console.log("date matched");
              return true; // Return true if both conditions are met, to store this object
            }
          }

          return false; // No match found
        });

        // Log the matched object
        if (matchingPriceObject) {
          console.log("Matching Price Object: ", matchingPriceObject);
        }

        setPricePerPerson(matchingPriceObject?.price_per_person);

        const monthName = dateToUse.toLocaleString("default", { month: "long" });

        const matchingMonth = pDetails?.[0]?.profitMargin?.[0]?.month_and_margin_user.find(
          (item) => item.month_name === monthName
        );

        // console.log(matchingPriceObject)
        // console.log(matchingMonth)

        const finalAmount = Number(matchingPriceObject?.price_per_person) * (Number(matchingMonth?.margin_percentage) / 100);
        setPricePerPerson(matchingPriceObject?.price_per_person + finalAmount);
        console.log(finalAmount)
      }
    }

  };

  const PackageDetails = async () => {
    const res = await fetch(
      `${BASE_URL}package/getPackageData?package_id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
  
    setPDetails(data.data);
    setHotel_itienrary(data?.data?.[0]?.hotel_itienrary);
  
    // Check price for current date or next month 1st date
    const dateToUse = availableDate ? new Date(availableDate) : new Date();
    
    const checkPriceForDate = (date) => {
      return data?.data?.[0]?.price_and_date.find((item) => {
        const startDate = new Date(item.price_start_date);
        const endDate = new Date(item.price_end_date);
        
        if (date >= startDate && date <= endDate) {
          return true;
        }
        return false;
      });
    };
  
    // First try to find price for the current date
    let matchingPriceObject = checkPriceForDate(dateToUse);
  
    // If no match for current date, check the first date of the next month
    if (!matchingPriceObject) {
      const nextMonthDate = new Date(dateToUse.getFullYear(), dateToUse.getMonth() + 1, 1);
      matchingPriceObject = checkPriceForDate(nextMonthDate);
    }
  
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    let currentMonthIndex = dateToUse.getMonth();
  
    let matchingMonth;
    for (let i = 0; i < 12; i++) {
      const monthToCheck = months[(currentMonthIndex + i) % 12];
      matchingMonth = data?.data?.[0]?.profitMargin?.[0]?.month_and_margin_user.find(
        (item) => item.month_name === monthToCheck
      );
      if (matchingMonth) {
        break;
      }
    }
  
    if (matchingMonth) {
      const marginPercentage = Number(matchingMonth.margin_percentage);
      const finalAmount = Number(matchingPriceObject?.price_per_person) * (marginPercentage / 100);
      setPricePerPerson(matchingPriceObject?.price_per_person + finalAmount);
    } else {
      setPricePerPerson(matchingPriceObject?.price_per_person);
    }
  };
  

  const alertShow = () => {
    const token = localStorage.getItem("userToken")
    token ? handleShow2() : alert("please Login First")
  }

  useEffect(() => {
    PackageDetails()
    getCategoty()
    window.scrollTo(0, 0)
  }, [])

  const [privacyPolicy, setPrivacy] = useState([])

  const privacypolicies = async () => {
    const res = await fetch(`${BASE_URL}policy`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setPrivacy(data.data[2])
  }

  useEffect(() => {
    privacypolicies()
  }, [])


  const [fullname, setFullname] = useState("")
  const handleName = (e) => {
    setFullname(e.target.value)
  }

  const [departure, setDeparture] = useState("")
  const handleDeparture = (e) => {
    setDeparture(e.target.value)
  }

  const [email, setEmail] = useState("")
  const handleEmail = (e) => {
    setEmail(e.target.value)
  }

  const [number, setNumber] = useState("")
  const handleNumber = (e) => {
    setNumber(e.target.value)
  }


  const [customeCity, setCustomeCity] = useState("")

  const handleState = (e) => {
    const city = e.target.value
    setCustomeState(e.target.value)
    const selectedCities = selectedStates?.states?.find((e) => e?.name === city)
    setCities(selectedCities?.cities)
  }

  const [customeState, setCustomeState] = useState("")
  const handleCity = (e) => {
    setCustomeCity(e.target.value)
  }

  const [adult, setAdult] = useState();
  const [child, setChild] = useState();
  const [infant, setInfant] = useState();

  const handleAdultChange = (e) => setAdult(parseInt(e.target.value) || 0);
  const handleChildChange = (e) => setChild(parseInt(e.target.value) || 0);
  const handleInfantChange = (e) => setInfant(parseInt(e.target.value) || 0);

  const [start_date, setStartDate] = useState("");
  const handleStartdatechange = (e) => setStartDate(e.target.value);

  const [end_date, setEndDate] = useState("");
  const handleEnddatechange = (e) => setEndDate(e.target.value);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const userDetails = async () => {
    const token = localStorage.getItem("token");
    const res = await fetch(
      `${BASE_URL}payment/payment_status?merchantTransactionId=${merchentId}`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    if (!isSubmitted) {
      LastSubmit(data?.data?.merchantTransactionId, data?.data?.paymentInstrument?.type);
    }
  };

  useEffect(() => {
    if (merchentId !== undefined && merchentId !== "") {
      userDetails();
    }
  }, [merchentId]);

  const LastSubmit = async (tranjectionId, paymentType) => {
    const token = localStorage.getItem("userToken");
    const bidDetails = JSON.parse(localStorage.getItem("packageDetail"));

    const updatedBidDetails = {
      ...bidDetails,
      transaction_id: tranjectionId,
      payment_type: paymentType,
    };

    const res = await fetch(`${BASE_URL}bookpackage`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedBidDetails),
    });

    const data = await res.json();

    if (data.code === 200) {
      toast.success("Package Booked Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      // navigate("/my_package");

      handleClose1();
      setIsSubmitted(true); // Set the flag to true after submission
    }
  };

  const location = useLocation();

  const handlePrice = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const numberRegex = /^[0-9]+$/;
    const maxNumberLimit = 10; // Example maximum limit for mobile number

    // Validation: Empty Fields
    if (departure.trim() === "") {
      toast.error("Please Enter Departure!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (fullname.trim() === "") {
      toast.error("Please Enter Full Name!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!email) {
      toast.error("Please Enter Email Address!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    // Validation: Email Format
    if (!emailRegex.test(email)) {
      toast.error("Please Enter a Valid Email Address!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!number) {
      toast.error("Please Enter Mobile Number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    // Validation: Mobile Number Format and Length
    if (!numberRegex.test(number)) {
      toast.error("Please Enter a Valid Mobile Number!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (number.length !== maxNumberLimit) {
      toast.error(`Mobile Number Should Be Exactly ${maxNumberLimit} Digits!`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!customeState) {
      toast.error("Please Select a State!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!customeCity) {
      toast.error("Please Select a City!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!adult) {
      toast.error("Please Enter Total Adults!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    // Validation: Positive Numbers
    if (adult < 1) {
      toast.error("Adults must be at least 1!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (child < 0) {
      toast.error("Please Enter a Valid Number of Children!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (infant < 0) {
      toast.error("Please Enter a Valid Number of Infants!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!start_date) {
      toast.error("Please Select Start Date!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    if (!end_date) {
      toast.error("Please Select End Date!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    // Validation: Date Range
    if (new Date(end_date) < new Date(start_date)) {
      toast.error("End Date Cannot Be Earlier Than Start Date!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return;
    }

    const packageData = {
      total_adult: adult,
      total_child: child,
      total_infant: infant,
      contact_number: number,
      email_id: email,
      room_sharing: pDetails[0].room_sharing,
      totalnight: pDetails[0].total_nights,
      totaldays: pDetails[0].total_days,
      total_amount: pricePerPerson * (Number(adult) + Number(child) + Number(infant)),
      meal: pDetails[0].meal_required,
      meal_type: pDetails[0].meal_type,
      siteseeing: pDetails[0].sightseeing,
      transport_mode: pDetails[0].travel_by,
      hoteltype: pDetails[0].hotel_type.map(item => item + " Star").join(", "),
      priceperperson: pricePerPerson,
      vendorpriceperperson: pDetails?.[0]?.vender_price_per_person,
      category: pDetails[0].destination[0].destination_category_id,
      destination: pDetails?.[0]?.destination?.[0]?.destination_name,
      departure: departure,
      user_name: fullname,
      city: customeCity,
      state: customeState,
      approx_start_date: start_date,
      approx_end_date: end_date,
      transport_include_exclude: "no",
      total_person: adult + child + infant,
      booked_include: pDetails[0].include_service,
      booked_exclude: pDetails[0].exclude_service,
      booked_itinerary: pDetails[0].Itinaries,
      package_id: pDetails[0]._id,
    };

    localStorage.setItem('packageDetail', JSON.stringify(packageData));
    navigate(`/package-booking/${pDetails[0]._id}`);
  }

  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [formattedEndDate, setFormattedEndDate] = useState(null);

  useEffect(() => {

    const today = new Date();

    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const formattedTomorrow = tomorrow.toISOString().split('T')[0];
    setFormattedStartDate(formattedTomorrow);

    const endDate = pDetails?.[0]?.end_date.split('T')[0];
    setFormattedEndDate(endDate);
  }, [pDetails]);

  const [inquiryForm, setInquiryForm] = useState(false)

  const [newDeparture, setNewDeparture] = useState('');
  const [newFullname, setNewFullname] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newNumber, setNewNumber] = useState('');
  const [newCustomeState, setNewCustomeState] = useState('');
  const [newCustomeCity, setNewCustomeCity] = useState('');
  const [newAdult, setNewAdult] = useState(0);
  const [newChild, setNewChild] = useState(0);
  const [newInfant, setNewInfant] = useState(0);
  const [shareWith, setShareWith] = useState('admin');

  const handleNewDeparture = (e) => setNewDeparture(e.target.value);
  const handleNewName = (e) => setNewFullname(e.target.value);
  const handleNewEmail = (e) => setNewEmail(e.target.value);
  const handleNewState = (e) => setNewCustomeState(e.target.value);
  const handleNewCity = (e) => setNewCustomeCity(e.target.value);
  const handleNewAdultChange = (e) => setNewAdult(e.target.value);
  const handleNewChildChange = (e) => setNewChild(e.target.value);
  const handleNewInfantChange = (e) => setNewInfant(e.target.value);
  const handleShareWithChange = (e) => setShareWith(e.target.value);

  const [userDetail, setUserDetail] = useState("")

  const fetchUserData = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(
      `${BASE_URL}user/userprofile`,
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();

    setUserDetail(data?.data)

    if (data?.data && data.data.length > 0) {
      const userDetails = data.data[0].user_details[0];

      const selectedCities = selectedStates?.states?.find((e) => e?.name === userDetails.state)
      setCities(selectedCities?.cities)

      setNewFullname(userDetails.name || '');
      setNewEmail(userDetails.email_address || '');
      setNewCustomeState(userDetails.state || '');
      setNewCustomeCity(userDetails.city || '');
      setNewNumber(data.data[0].phone || '');
    }

  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const validateInquiryData = () => {
    if (!newDeparture) {
      toast.error("Departure is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }

    if (!newFullname) {
      toast.error("Full Name is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }

    if (!newEmail || !/\S+@\S+\.\S+/.test(newEmail)) {
      toast.error("A valid Email is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }

    const numberString = newNumber.toString();
    if (!numberString || numberString.length !== 10) {
      toast.error("A valid Mobile Number (10 digits) is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }

    if (!newCustomeState) {
      toast.error("State is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }

    if (!newCustomeCity) {
      toast.error("City is required.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }

    if (Number(newAdult) < 0) {
      toast.error("Number of adults, children, and infants cannot be negative.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }

    if (!shareWith) {
      toast.error("Please select who to share with.", { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 3000 });
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateInquiryData()) {
      return;
    }

    const inquiryData = {
      departure: newDeparture,
      fullname: newFullname,
      email: newEmail,
      number: newNumber,
      state: newCustomeState,
      city: newCustomeCity,
      total_adult: newAdult,
      total_child: newChild,
      total_infants: newInfant,
      package_id: id,
      share_with: shareWith,
    };

    try {
      const response = await axios.post(`${BASE_URL}api/inquiry/create`, inquiryData);
      toast.success("Inquiry Sent Successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setInquiryForm(false)
      // Optionally, you can reset the form or navigate to another page
    } catch (error) {
      console.error('Error submitting inquiry', error);
      toast.error("Error submitting inquiry. Please try again later.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <Header setShow={setShow} show={show} />
      {pDetails.map((ele) => {
        return (
          <>
            <section className='container-customes padding-costume-container py-xl-4 py-sm-4 py-2'>
              <div className='row'>
                <div className='col-xl-8 col-lg-6 col-sm-12 col-12 m-0 posi-first'>
                  <div>
                    <img
                      // src="/bkg1.png"
                      // src={packageData}
                      src={ele.Place[0].photo}
                      alt=''
                      className='w-100 rounded h-md-50 meadia-for-package-details-img'
                    />
                  </div>
                  <div className='mt-3 hover-buttons-media'>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("overview")}
                      >
                        Overview
                      </button>
                    </div>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("services")}
                      >
                        Services
                      </button>
                    </div>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("itenarary")}
                      >
                        Itinerary
                      </button>
                    </div>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("hotels")}
                      >
                        Hotels
                      </button>
                    </div>
                    <div className='me-3 mb-1'>
                      <button
                        class='button-47'
                        role='button'
                        onClick={() => scrollTo("privacy")}
                      >
                        Policy
                      </button>
                    </div>
                  </div>
                  <Uppage />
                  <section>
                    <div className=''>
                      <div className='mb-4'>
                        <section className='mb-3 mt-3' ref={overRef}>
                          <div className=''>
                            <div>
                              <button className='w-100 over-btn font700 text18 lh19'>
                                <img src='/c-icon.png' className=' me-3' />
                                Overview
                              </button>
                            </div>
                          </div>
                        </section>
                        <section className='text-start'>
                          <div className=''>
                            <Row>

                              <div className='col-12 mb-3'>
                                <div className='amh_to_hima'>
                                  <div>
                                    <h6 className=' pb-2 mb-0 cmntext font700 text20'>
                                      <FontAwesomeIcon
                                        icon={faHotel}
                                        className='cmnclr'
                                      />{" "}
                                      Hotel & Food
                                    </h6>
                                  </div>
                                  <div className=''>
                                    <div className='row'>
                                      <div className='col-12 px-0'>
                                        <div className='amh_to_hima_border ms-3 px-4 py-2'>
                                          <p className='cmnp py-1 text14'>
                                            <FontAwesomeIcon
                                              icon={faDoorClosed}
                                              className='cmnclr'
                                            />{" "}
                                            <b>Room Sharing</b> : {ele?.room_sharing}
                                          </p>
                                          <p className='cmnp py-1 text14'>
                                            <FontAwesomeIcon
                                              icon={faBowlFood}
                                              className='cmnclr'
                                            />{" "}
                                            <b>Meal</b> : {ele?.meal_required?.join(",")}
                                          </p>
                                          <p className='cmnp py-1 text14'>
                                            <FontAwesomeIcon
                                              icon={faCookie}
                                              className='cmnclr'
                                            />{" "}
                                            <b>Meal Type</b> : {ele?.meal_type}
                                          </p>
                                          <p className='cmnp py-1 text14'>
                                            <FontAwesomeIcon
                                              icon={faMountainCity}
                                              className='cmnclr'
                                            />{" "}
                                            <b>Sightseeing</b> : {ele?.sightseeing}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='mt-3 mb-2'>
                                    {ele.travel_by === "Bus" && (
                                      <>
                                        <h6 className='cmntext font700 text20'>
                                          <FontAwesomeIcon
                                            icon={faBus}
                                            className='cmnclr'
                                          />{" "}
                                          Travel By
                                        </h6>
                                      </>
                                    )}
                                    {ele.travel_by === "Train" && (
                                      <>
                                        <h6 className='cmntext font700 text20'>
                                          <FontAwesomeIcon
                                            icon={faTrainSubway}
                                            className='cmnclr'
                                          />{" "}
                                          Travel By
                                        </h6>
                                      </>
                                    )}
                                    {ele.travel_by === "Flight" && (
                                      <>
                                        <h6 className='cmntext font700 text20'>
                                          <FontAwesomeIcon
                                            icon={faPlane}
                                            className='cmnclr'
                                          />{" "}
                                          Travel By
                                        </h6>
                                      </>
                                    )}
                                    {ele.travel_by === "Car" && (
                                      <>
                                        <h6 className='cmntext font700 text20'>
                                          <FontAwesomeIcon
                                            icon={faTaxi}
                                            className='cmnclr'
                                          />{" "}
                                          Travel By
                                        </h6>
                                      </>
                                    )}
                                  </div>
                                  <div className='cmn'>
                                    <p className='cmnp ms-4 py-2 text14'>{ele?.travel_by}</p>
                                  </div>
                                  <div>
                                    <div className='mt-3 mb-2'>
                                      <h6 className='cmntext font700 text20'>
                                        <FontAwesomeIcon
                                          icon={faCalendarDays}
                                          className='cmnclr'
                                        />{" "}
                                        Approx Departure Between{" "}
                                      </h6>
                                    </div>
                                    <div className='cmn'>
                                      <p className='cmnp ms-4 py-2 text14'>
                                        {formatDate(ele?.start_date)} to {formatDate(ele?.end_date)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>

                            <div className='cmn  text-start mt-3'>
                              <div className='mt-3 mb-2 text-start cmnp ms-4 pb-2'>
                                <h6 className='cmntext mb-1 font700 text20'>
                                  <FontAwesomeIcon
                                    icon={faHouseFloodWaterCircleArrowRight}
                                    className='cmnclr'
                                  />{" "}
                                  Other Details
                                </h6>
                              </div>
                              <p className='cmnp ms-4 py-2 mb-3 text14'>{ele?.more_details}</p>

                            </div>
                          </div>
                        </section>
                      </div>

                      <div className='details-service' ref={services}>
                        <button className='mb-4 font700 text18 lh19'><FontAwesomeIcon className='me-3' icon={faBrush} /> Services</button>
                        <div className='media-services'>
                          <div className='row gx-xl-1 gx-lg-5 gx-md-4 gx-sm-0 IE-border'>
                            <div className='col-xl-6 col-12'>
                              <div className='hotel-Included'>
                                <h4 className="font700 text20 text-start">
                                  <FontAwesomeIcon icon={faCircleCheck} />{" "}
                                  Included
                                </h4>
                                <div class='content-green'>
                                  {ele.include_service.map((e) => {
                                    return (
                                      <>
                                        <p className="text15 d-flex align-items-start">
                                          <FontAwesomeIcon icon={faCheck} className="text16 me-2" />
                                          <span>
                                            {e.include_services_value}
                                          </span>
                                        </p>
                                      </>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className='col-xl-6 col-12'>
                              <div className='hotel-excluded'>
                                <h4 className="font700 text20 text-start">
                                  <FontAwesomeIcon icon={faCircleXmark} />{" "}
                                  Excluded
                                </h4>
                                <div className='content-red'>
                                  {ele.exclude_service.map((e1) => {
                                    return (
                                      <>
                                        <p className="text15 d-flex align-items-start">
                                          <FontAwesomeIcon icon={faXmark} className="me-2 text16" />
                                          <span>
                                            {e1.exclude_services_value}
                                          </span>
                                        </p>
                                      </>
                                    )
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className='details-service mt-4 mb-1'
                        ref={itenarary}
                      >
                        <button className='mb-4 font700 text18 lh19'><FontAwesomeIcon
                          icon={faHandshakeAlt}
                          className='me-2'
                        />Itinerary</button>
                      </div>
                      <div className='itenary-days'>
                        {ele.Itinaries && ele.Itinaries.map((e3, i) => {
                          return (
                            <>
                              <div className="py-2">
                                <h6 className="cmntext mb-2 font700 text20">Day {i + 1}</h6>
                                <div className='cmn p-2'>

                                  <Row className='align-items-start'>
                                    <div className='col-lg-9'>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1 text15 font600'>Title</h6>
                                        <p className='cmnp cmnclr font-weight-bolder text15'>
                                          {e3.title}{" "}
                                        </p>
                                      </div>
                                      <div className='py-1'>
                                        <h6 className='cmnp cmnclr1 text15 font600'>Activity</h6>
                                        <p className='cmnp cmnclr font-weight-bolder text15'>
                                          <div
                                            style={{ textAlign: "justify" }}
                                            dangerouslySetInnerHTML={{
                                              __html: e3.activity,
                                            }}
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    {e3.photo && <div className='col-lg-3'>
                                      <div className='text-center py-2'>
                                        <img src={e3.photo} className='img-fluid' style={{ height: "120px", width: "120px" }} />
                                      </div>
                                    </div>}
                                  </Row>


                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </section>

                  <section className='pt-4' ref={hotels}>
                    <div className='hotels-section'>
                      <div className='last-hotels'>
                        <h3 className="text-start font700 text20">Hotels</h3>

                        {/* <!--------------------------- part-1 ---------------------------------------------> */}

                        <div className='hotel-president'>
                          {hotel_itienrary && hotel_itienrary?.map((e, index) => (
                            <div className='row gx-5 p-2 second-changes'>
                              <div className='col-xl-6 col-12'>
                                <div id={`carouselExampleCaptions-${index}`} className="carousel slide" data-bs-ride="carousel">
                                  <div className="carousel-indicators">
                                    {e?.hotel_photo?.map((_, idx) => (
                                      <button
                                        key={idx}
                                        type="button"
                                        data-bs-target={`#carouselExampleCaptions-${index}`}
                                        data-bs-slide-to={idx}
                                        className={idx === 0 ? "active" : ""}
                                        aria-current={idx === 0 ? "true" : "false"}
                                        aria-label={`Slide ${idx + 1}`}
                                      ></button>
                                    ))}
                                  </div>
                                  <div className="carousel-inner">
                                    {e?.hotel_photo?.map((ele, idx) => (
                                      <div key={idx} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                                        <img src={ele} className="d-block w-100" alt="..." />
                                      </div>
                                    ))}
                                  </div>
                                  <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                  </button>
                                  <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleCaptions-${index}`} data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                  </button>
                                </div>
                              </div>
                              <div className='col-xl-6 col-12 first-change'>
                                <div className='d-inline-block mb-md-2'>
                                  <span className='d-flex day1-2  text-left align-items-center'>
                                    <div className='day-1-2 py-1 font-changes'>
                                      <h6 className="font700 text18">
                                        Day <br /> {e?.days?.split(',').sort((a, b) => a - b).join(', ')}
                                      </h6>
                                    </div>
                                    <div className=' font-changes'>
                                      <h6 className='mb-1 pe-5 font700 text18'>
                                        {e?.hotel_name}
                                      </h6>
                                      <p className='mb-0 text-start text16'>
                                        {e?.hotel_address}
                                      </p>
                                    </div>
                                  </span>
                                </div>
                                <div className='hotels-star'>
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                  <FontAwesomeIcon
                                    icon={faStar}
                                    className='p-1'
                                    style={{ color: "yellow" }}
                                  />
                                </div>
                                <div className='hotels-footer'>
                                  <p className="text14">
                                    {e?.hotel_description}
                                    {/* {e?.hotel_address} <br />
                                    {e?.hotel_city},{e?.hotel_state} */}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>


                <div className='col-xl-4 col-lg-6 col-sm-12 col-12 m-0 posi-second position_relative'>
                  <div className='CuStom_package package_details_2 book_now_card py-3 px-xl-3 px-sm-3 px-1 hyhy mb-3 position_fixed'>
                    <div className='inner_packega'>
                      <div className=''>
                        <div className='border_bottom'>
                          <p className='text-19 mb-1 font700 text18 mb-3'>{ele.name}</p>
                          <div className="d-flex justify-content-between">
                            <div className="mb-2 d-flex align-items-start">
                              <p className='fw-bold pt-1 me-2'>
                                {ele.destination[0].destination_name}
                              </p>
                              <p className=''>
                                <button class='button-13' role='button'>
                                  {ele.total_days}D/{ele.total_nights}N
                                </button>
                              </p>
                            </div>
                            <div className='for-18000 mb-2'>
                              <div className='d-flex justify-content-end'>
                                <p className=''></p>
                                <p className='ms-2 mb-0 price-per-person-media text26 font700'>
                                  <FontAwesomeIcon
                                    className='pe-2'
                                    icon={faIndianRupeeSign}
                                  />
                                  {pricePerPerson}
                                </p>
                                <div className='per-person-media text-end'>
                                  per person
                                </div>
                              </div>
                              <div className='Per_Person text-end'>
                                *Excluding applicable taxes
                              </div>
                            </div>
                          </div>
                          <div className="mb-3 d-flex align-items-center">
                            <p
                              className='mb-0 ms-1 me-2 text14 font700'
                            >
                              Package Type :
                            </p>
                            <button
                              class='button-17 me-1'
                              role='button'
                            >
                              {ele.package_type}
                            </button>
                          </div>
                          {ele.destination_category_id.length > 0 ? (
                            <>
                              <div className='mb-4 d-flex align-items-center flex-wrap gap-2'>
                                <p
                                  className='mb-0 ms-1 me-2 text14 font700'
                                >
                                  Package Best For :
                                </p>
                                {ele.destination_category_id.map((e) => {
                                  return (
                                    <>
                                      <button
                                        class='button-17 me-1'
                                        role='button'
                                      >
                                        {e.category_name}
                                      </button>
                                    </>
                                  )
                                })}
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className='pad_img d-flex text-center justify-content-start gap-2 ms-2'>
                            <div>
                              <FontAwesomeIcon
                                icon={faHotel}
                                className='media-for-icon text22'
                              />
                              <br />
                              {ele.hotel_type.map(a =>
                                <div style={{ fontSize: "12px" }}>{a} Stars</div>
                              )}
                            </div>
                            <div className='mx-3'>
                              <FontAwesomeIcon
                                icon={faUtensils}
                                className='media-for-icon text22'
                              />
                              <br />
                              <span>Meals ({ele.meal_type})</span>
                              <div title={ele.meal_required} style={{ fontSize: "12px" }}>
                                {ele.meal_required.map(a => a.charAt(0)).join(', ')}
                              </div>
                            </div>

                            {ele.sightseeing === "yes" && <div>
                              <FontAwesomeIcon
                                icon={faBinoculars}
                                className='media-for-icon text22'
                              />
                              <br />
                              <span className="text12">Sightseeing</span>
                            </div>}
                            <div className='mx-3'>
                              {ele.travel_by === "Bus" && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faBus}
                                    className='media-for-icon text22'
                                  />
                                  <br />
                                  <span className="text12">Bus</span>
                                </>
                              )}
                              {ele.travel_by === "Train" && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faTrainSubway}
                                    className='media-for-icon text22'
                                  />
                                  <br />
                                  <span className="text12">Train</span>
                                </>
                              )}
                              {ele.travel_by === "Flight" && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faPlane}
                                    className='media-for-icon text22'
                                  />
                                  <br />
                                  <span className="text12">Flight</span>
                                </>
                              )}
                              {ele.travel_by === "Car" && (
                                <>
                                  <FontAwesomeIcon
                                    icon={faTaxi}
                                    className='media-for-icon text22'
                                  />
                                  <br />
                                  <span className="text12">Car</span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-start check-availability-input ps-2">
                          <div className="d-flex justify-content-start flex-column check-availability-input">
                            <input
                              type="date"
                              name=""
                              id=""
                              value={availableDate}
                              min={formattedStartDate}
                              onChange={handleCheckAvailability}
                              placeholder="dd-mm-yyyy"
                              style={{ paddingBottom: "5px" }}
                            />
                            {errorMessageShow && (
                              <p className="mb-0">*This Package Is not Available For Selcted Date</p>
                            )}
                            {errorMessageShow && (
                              <p className="mb-0">*Please Choose Onther Date</p>
                            )}
                          </div>
                        </div>
                        {errorMessageShow && (
                          <div className='px-2 mt-3'>
                            <div className='c-top-p text-center mt-1 w-100'>
                              <button
                                href=''
                                className='c_btn2 text-20 border-0 w-100'
                                onClick={() => setInquiryForm(true)}
                              >
                                Inquiry Now
                              </button>
                            </div>
                          </div>
                        )}
                        <div className='px-2'>
                          <div className='c-top-p text-center mt-1 w-100'>
                            <button
                              href=''
                              className='c_btn2 text-20 border-0 w-100'
                              onClick={handleCheck}
                            >
                              Check Availability
                            </button>
                          </div>
                          {displayBook && (
                            <div className='c-top-p text-center mt-1 w-100'>
                              <button
                                href=''
                                className='c_btn2 text-20 border-0 w-100'
                                onClick={alertShow}
                              >
                                Book Now
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Modal show={inquiryForm} onHide={() => setInquiryForm(false)}>
                  <Modal.Body>
                    <div>
                      <Row>
                        <div className='col-md-12 col-12'>
                          <form>
                            <div
                              style={{
                                background: "linear-gradient(#00363D, #005C63)",
                                color: "#fff",
                                borderRadius: "8px",
                              }}
                              className='p-1'
                            >
                              <p className='inquiry-form-header'>
                                Inquiry Detail
                              </p>
                            </div>
                            <div className='cmn p-1'>
                              {/* <p className='text-center'>Personal Details</p> */}
                              <div>
                                {/* Your form controls here */}
                                <Form.Control
                                  type='text'
                                  placeholder='Departure'
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "12px",
                                  }}
                                  value={newDeparture}
                                  onChange={handleNewDeparture}
                                  className='mt-1 my-1'
                                  name='newDeparture'
                                />

                                <Form.Control
                                  type='text'
                                  placeholder='Full Name'
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "12px",
                                  }}
                                  value={newFullname}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^A-Za-z ]/g, "");
                                    handleNewName(e);
                                  }}
                                  className='mt-1 my-1'
                                  name='newFullname'
                                />

                                <Form.Control
                                  type='text'
                                  placeholder='Email id'
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "12px",
                                  }}
                                  value={newEmail}
                                  onChange={handleNewEmail}
                                  className='mt-1 my-1'
                                  name='newEmail'
                                />

                                <Form.Control
                                  type='text'
                                  placeholder='Mobile Number'
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "12px",
                                  }}
                                  value={newNumber}
                                  onInput={(e) => {
                                    let value = e.target.value.replace(/[^0-9]/g, "");
                                    if (value.length > 10) {
                                      value = value.substring(0, 10);
                                    }
                                    setNewNumber(value);
                                  }}
                                  className='mt-1 my-1'
                                  name='newNumber'
                                />

                                <div className="row gx-2">
                                  <div className="col-6">
                                    <Form.Select
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "12px",
                                      }}
                                      value={newCustomeState}
                                      onChange={handleNewState}
                                      className='mt-1 my-1'
                                      name='newCustomeState'
                                    >
                                      <option value="">Select State</option>
                                      {selectedStates && selectedStates?.states?.map((e) => (
                                        <option key={e.name} value={e.name}>{e.name}</option>
                                      ))}
                                    </Form.Select>
                                  </div>

                                  <div className="col-6">
                                    <Form.Select
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "12px",
                                      }}
                                      value={newCustomeCity}
                                      onChange={handleNewCity}
                                      className='mt-1 my-1'
                                      name='newCustomeCity'
                                    >
                                      <option value="">Select City</option>
                                      {cities && cities?.map((e) => (
                                        <option key={e.name} value={e.name}>{e.name}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                                </div>

                                <div className="row gx-2">
                                  <div className="col-12 mt-2">
                                    <h6 className="mb-0">Total Travellers</h6>
                                  </div>
                                  <div className="col-4">
                                    <Form.Control
                                      type="number"
                                      value={newAdult}
                                      onChange={handleNewAdultChange}
                                      min="0"
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "12px",
                                      }}
                                      placeholder='Adult'
                                      className='mt-1 my-1'
                                      name='newAdult'
                                    />
                                  </div>
                                  <div className="col-4">
                                    <Form.Control
                                      type="number"
                                      value={newChild}
                                      onChange={handleNewChildChange}
                                      min="0"
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "12px",
                                      }}
                                      placeholder='Kids'
                                      className='mt-1 my-1'
                                      name='newChild'
                                    />
                                  </div>
                                  <div className="col-4">
                                    <Form.Control
                                      type="number"
                                      value={newInfant}
                                      onChange={handleNewInfantChange}
                                      min="0"
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "12px",
                                      }}
                                      placeholder='Infants'
                                      className='mt-1 my-1'
                                      name='newInfant'
                                    />
                                  </div>
                                </div>

                                <div className="row gx-2">
                                  <div className="col-12 mb-2">
                                    <h6 className="mb-0 mt-2">Share This Data With</h6>
                                  </div>
                                  <div className="col-12">
                                    <div className="">
                                      <Form.Check
                                        type="radio"
                                        label="Admin Only"
                                        value="admin"
                                        checked={shareWith === 'admin'}
                                        onChange={handleShareWithChange}
                                        name="shareWith"
                                        style={{ fontSize: "12px" }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="">
                                      <Form.Check
                                        type="radio"
                                        label="Admin And Agency"
                                        value="admin and agency"
                                        checked={shareWith === 'admin and agency'}
                                        onChange={handleShareWithChange}
                                        name="shareWith"
                                        style={{ fontSize: "12px" }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* <Button onClick={handleSubmit}>Submit Inquiry</Button> */}
                              </div>


                            </div>
                            <div className='text-center pt-2'>
                              <p
                                className='w-100 border-0 py-1'
                                style={{
                                  background: "#09646D",
                                  color: "#fff",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleSubmit(pricePerPerson)}
                              >
                                Submit Inquiry
                              </p>
                            </div>
                          </form>
                        </div>
                      </Row>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal show={show2} onHide={handleClose2}>
                  <Modal.Body>
                    <div>
                      <Row>
                        <div className='col-md-12 col-12'>
                          <form>
                            <div
                              style={{
                                background: "linear-gradient(#00363D, #005C63)",
                                color: "#fff",
                                borderRadius: "8px",
                              }}
                              className='p-1'
                            >
                              <span className='cmnp textcm'>
                                <FontAwesomeIcon
                                  icon={faIndianRupeeSign}
                                  className='ps-2'
                                />{" "}
                                {pricePerPerson}
                              </span>
                              <p className='cmnp pb-1 textcm'>
                                Per person on twin sharing
                              </p>
                              <div className=' textcm'>
                                <FontAwesomeIcon icon={faStar} className='inner_star' />
                                <FontAwesomeIcon icon={faStar} className='inner_star' />
                                <FontAwesomeIcon icon={faStar} className='inner_star' />
                                <FontAwesomeIcon icon={faStar} className='inner_star' />
                                <FontAwesomeIcon icon={faStar} className='inner_star' />
                              </div>
                            </div>
                            <div className='cmn p-1 mt-3 px-3'>
                              <p className='text-center text20 font700 mb-3 mt-1'>Booking Details</p>
                              <div>
                                <Form.Control
                                  type='text'
                                  placeholder='Departure'
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "14px",
                                  }}
                                  value={departure}
                                  onChange={handleDeparture}
                                  className='mt-1 my-2 py-2'
                                  name='email'
                                // readOnly
                                />
                                <Form.Control
                                  type='text'
                                  placeholder='Full Name'
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "14px",
                                  }}
                                  value={fullname}
                                  onInput={(e) => {
                                    e.target.value = e.target.value.replace(
                                      /[^A-Za-z ]/g,
                                      "",
                                    )
                                    handleName(e)
                                  }}
                                  className='mt-1 my-2 py-2'
                                  name='fname'
                                // readOnly
                                />



                                <Form.Control
                                  type='text'
                                  placeholder='Email id'
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "14px",
                                  }}
                                  value={email}
                                  onChange={handleEmail}
                                  className='mt-1 my-2 py-2'
                                  name='email'
                                // readOnly
                                />

                                <Form.Control
                                  type='text'
                                  placeholder='Mobile Number'
                                  style={{
                                    borderLeft: "none",
                                    borderRight: "none",
                                    borderTop: "none",
                                    fontSize: "14px",
                                  }}
                                  value={number}
                                  onInput={(e) => {
                                    // Remove any non-digit characters
                                    let value = e.target.value.replace(/[^0-9]/g, "");

                                    // Limit to 10 digits
                                    if (value.length > 10) {
                                      value = value.substring(0, 10);
                                    }

                                    // Update state with the formatted value
                                    // handleNumber(value);
                                    setNumber(value);
                                  }}
                                  className='mt-1 my-2 py-2'
                                  name='mobile'
                                // readOnly
                                />

                                <div className="row gx-2">
                                  <div className="col-6">
                                    <Form.Select
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      value={customeState}
                                      onChange={handleState}
                                      className='mt-1 my-2 py-2'
                                      name='Departure'
                                    >
                                      <option value="">Select State</option>
                                      {selectedStates && selectedStates?.states?.map((e) => (
                                        <option value={e?.name}>{e.name}</option>
                                      ))}
                                    </Form.Select>
                                  </div>

                                  <div className="col-6">
                                    <Form.Select
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      value={customeCity}
                                      onChange={handleCity}
                                      className='mt-1 my-2 py-2'
                                      name='Departure'
                                    >
                                      <option value="">Select City</option>
                                      {cities && cities?.map((e) => (
                                        <option value={e?.name}>{e.name}</option>
                                      ))}
                                    </Form.Select>
                                  </div>
                                </div>
                                <div className="row gx-2">
                                  <div className="col-4">
                                    <Form.Control
                                      type="number"
                                      value={adult}
                                      onChange={handleAdultChange}
                                      min="0"
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      placeholder='Adult'
                                      className='mt-1 my-2 py-2'
                                      name='adult'
                                    />
                                  </div>
                                  <div className="col-4">
                                    <Form.Control
                                      type="number"
                                      value={child}
                                      onChange={handleChildChange}
                                      min="0"
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      placeholder='Kids'
                                      className='mt-1 my-2 py-2'
                                      name='kids'
                                    />
                                  </div>
                                  <div className="col-4">
                                    <Form.Control
                                      type="number"
                                      value={infant}
                                      onChange={handleInfantChange}
                                      min="0"
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      placeholder='Infants'
                                      className='mt-1 my-2 py-2'
                                      name='infant'
                                    />
                                  </div>
                                </div>
                                <div className="row gx-2">
                                  <div className="col-6">
                                    <div style={{ fontSize: "14px", marginTop: "10px" }}>Approx Start Date:</div>
                                    <Form.Control
                                      type='date'
                                      placeholder='State'
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      value={start_date}
                                      onChange={handleStartdatechange}
                                      className='mt-1 my-2 py-2'
                                      name='startdate'
                                      min={getTomorrowDate()}
                                    />
                                  </div>
                                  <div className="col-6">
                                    <div style={{ fontSize: "14px", marginTop: "10px" }}>Approx End Date:</div>
                                    <Form.Control
                                      type='date'
                                      placeholder='City'
                                      style={{
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderTop: "none",
                                        fontSize: "14px",
                                      }}
                                      value={end_date}
                                      onChange={handleEnddatechange}
                                      className='mt-1 my-2 py-2'
                                      name='enddate'
                                      disabled={!start_date}
                                      min={start_date}
                                    // readOnly
                                    />
                                  </div>
                                </div>
                              </div>


                            </div>
                            <div className='text-center pt-2 px-3'>
                              <p
                                className='w-100 border-0 py-2'
                                style={{
                                  background: "#09646D",
                                  color: "#fff",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handlePrice(pricePerPerson)}
                              >
                                Book Now
                              </p>
                            </div>
                          </form>
                        </div>
                      </Row>
                    </div>
                  </Modal.Body>
                </Modal>
                <div className='col-12 posi-third' ref={privacy}>
                  <div className='mt-5'>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: privacyPolicy.term_and_condition_content,
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </>
        )
      })}

      <hr />
      <Footer />
    </div>
  )
}

export default Packega_details
