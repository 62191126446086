import React, { useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import {
  faArrowLeft,
  faBell,
  faBrush,
  faCheck,
  faCheckCircle,
  faClipboard,
  faEnvelopeOpen,
  faLayerGroup,
  faLocationPin,
  faStar,
  faUser,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import Header from "../Project/Header"
import My_pannel from "./My_pannel"
import Header2 from "./Header2"
import { useNavigate } from "react-router-dom"
import { BASE_URL } from "../BASE_URL"
import ProfileSection from "./SecondNavbar"

function Booked_packega(props) {
  const [bookData, setBookData] = useState([])
  const navigate = useNavigate()

  const displayBookPackage = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}bookpackage/bookpackageforagency_jaydev`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setBookData(data.data)
  }

  useEffect(() => {
    displayBookPackage()
  }, [])

  const [editedata, setEditedData] = useState("")

  const Call = async () => {
    const token = localStorage.getItem("vendorToken")
    const res = await fetch(`${BASE_URL}agency/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
    const data = await res.json()
    setEditedData(data?.data?.[0])
  }

  useEffect(() => {
    Call()
  }, [])

  const handleNav = () => {
    navigate("/vendor/myprofile")
  }

  const uniqueValues = new Set()

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <div style={{ backgroundColor: "whitesmoke" }}>
        <ProfileSection title="Booked Package List" />

          <section className='booked_packega_margin'>
            {bookData && bookData.map((ele, index) => {
              return (
                <>
                  <div className='mb-3' key={ele._id}>
                    <Row>
                      <div className='col-xl-6 col-sm-12 col-12 text-center m-0 p-0 CuStom_package_image'>
                        <img
                          src='/C-packega.png'
                          className='img-fluid w-100'
                          alt={ele._id}
                        />
                      </div>
                      <div className='col-xl-6 col-sm-12 col-12 m-0 p-0 '>
                        <div
                          className='vendor_CuStom_package CuStom_package'
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className='inner_CuStom_package vendor'>
                            <p className='CuStom_package_header text20 font700 py-2'>
                              {ele.departure} to {ele.isBid ? ele.bidDetails[0].destination : ele.packageDetails[0].destination[0].destination_name}{" "}
                                ({ele.isBid ? ele.bidDetails[0].total_days : ele.packageDetails[0].total_days}D/{ele.isBid ? ele.bidDetails[0].total_nights : ele.packageDetails[0].total_nights}N)
                              
                            </p>
                            <div className='CuStom_package_content my_package_content'>
                              <p className='mb-xl-2 mb-lg-0'>
                                <span>Package ID </span>: {ele._id}
                              </p>
                              <div className='d-flex mb-xl-2 mb-lg-0'>
                                <p className='category_title'>Category</p>
                                &nbsp;:&nbsp;
                                <p className='category_desc'>
                                  {ele.isBid ? ele.destinationCategories.map(e => e.category_name).join(",") : ele.destinationPackageCategories.map(e => e.category_name).join(",")}
                                </p>
                              </div>
                              <p className='mb-xl-2 mb-lg-0 text-capitalize'>
                                <span>Contact Person </span>:{" "}
                                {ele.user_name}&nbsp;
                              </p>
                              <p className='mb-xl-2 mb-lg-0'>
                                <span>Contact No </span>:{" "}
                                {ele.contact_number}&nbsp;
                              </p>
                              <p className='mb-xl-2 mb-lg-0'>
                                <span>Booking Date </span>:{" "}
                                {ele?.approx_start_date?.slice(0, 10)} to {ele?.approx_end_date?.slice(0, 10)}
                              </p>

                              <p className='mb-xl-2 mb-lg-0'>
                                <span>Total Person </span>:{" "}
                                {ele.total_child + ele.total_adult + ele.total_infant}
                              </p>
                              <div className='view_btn batanv2 d-flex c-top-p my-1'>
                                <Link
                                  to={`/vendor/Book-packega/details/${ele._id}`}
                                  style={{
                                    background: "#09646d",
                                  }}
                                  onClick={() =>
                                    localStorage.setItem(
                                      "destination",
                                      ele.destination,
                                    )
                                  }
                                >
                                  View
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </>
              )
            })}

          </section>

          <section className='booked_packega_margin'>
            {bookData && bookData.map((ele, index) => {
              return (
                <>
                  <div className='mb-3' key={ele._id}>
                    <Row>
                      <div className='col-xl-6 col-sm-12 col-12 text-center m-0 p-0 CuStom_package_image'>
                        <img
                          src='/C-packega.png'
                          className='img-fluid w-100'
                          alt={ele._id}
                        />
                      </div>
                      <div className='col-xl-6 col-sm-12 col-12 m-0 p-0 '>
                        <div
                          className='vendor_CuStom_package CuStom_package'
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className='inner_CuStom_package vendor'>
                            <p className='CuStom_package_header text20 font700 py-2'>
                              {ele.departure} to {ele.isBid ? ele.bidDetails[0].destination : ele.packageDetails[0].destination[0].destination_name}{" "}
                                ({ele.isBid ? ele.bidDetails[0].total_days : ele.packageDetails[0].total_days}D/{ele.isBid ? ele.bidDetails[0].total_nights : ele.packageDetails[0].total_nights}N)
                              
                            </p>
                            <div className='CuStom_package_content my_package_content'>
                              <p className='mb-xl-2 mb-lg-0'>
                                <span>Package ID </span>: {ele._id}
                              </p>
                              <div className='d-flex mb-xl-2 mb-lg-0'>
                                <p className='category_title'>Category</p>
                                &nbsp;:&nbsp;
                                <p className='category_desc'>
                                  {ele.isBid ? ele.destinationCategories.map(e => e.category_name).join(",") : ele.destinationPackageCategories.map(e => e.category_name).join(",")}
                                </p>
                              </div>
                              <p className='mb-xl-2 mb-lg-0 text-capitalize'>
                                <span>Contact Person </span>:{" "}
                                {ele.user_name}&nbsp;
                              </p>
                              <p className='mb-xl-2 mb-lg-0'>
                                <span>Contact No </span>:{" "}
                                {ele.contact_number}&nbsp;
                              </p>
                              <p className='mb-xl-2 mb-lg-0'>
                                <span>Booking Date </span>:{" "}
                                {ele?.approx_start_date?.slice(0, 10)} to {ele?.approx_end_date?.slice(0, 10)}
                              </p>

                              <p className='mb-xl-2 mb-lg-0'>
                                <span>Total Person </span>:{" "}
                                {ele.total_child + ele.total_adult + ele.total_infant}
                              </p>
                              <div className='view_btn batanv2 d-flex c-top-p my-1'>
                                <Link
                                  to={`/vendor/Book-packega/details/${ele._id}`}
                                  style={{
                                    background: "#09646d",
                                  }}
                                  onClick={() =>
                                    localStorage.setItem(
                                      "destination",
                                      ele.destination,
                                    )
                                  }
                                >
                                  View
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                </>
              )
            })}

          </section>
        </div>
      </div>
    </>
  )
}

export default Booked_packega
