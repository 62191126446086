/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import Header from "./Header"
import { Row, Container } from "react-bootstrap"
import { useEffect } from "react"
import { useState } from "react"
import { BASE_URL } from "../BASE_URL"
import { useLocation, useNavigate } from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import Footer from "./Footer"

const formatDate = (dateString) => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date format';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};


function My_package(props) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location)

  const [bookedData, setBookedData] = useState("")
  const [bookedHotelData, setBookedHotelData] = useState([])
  const [bookedCarData, setBookedCarData] = useState([])
  const [mergedData, setMergedData] = useState([]);
  console.log(mergedData)

  const [isBookingCancel, setIsBookingCancel] = useState(false)
  const [bookingId, setBookingId] = useState("")

  const [cancelledBookingId, setCancelledBookingId] = useState([])

  const [error, setError] = useState("")

  const [reason, setReason] = useState("")

  const fetchBookedPackage = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const res = await fetch(`${BASE_URL}bookpackage/jaydev`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        throw new Error('Failed to fetch booked packages');
      }

      const data = await res.json();
      setBookedData(data.data || []); // Ensure data is an array or empty array
    } catch (err) {
      console.error(err);
      setError('Failed to load booked packages');
    }
  };

  const fetchBookedHotels = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const res = await fetch(`${BASE_URL}hotel_booking_syt`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        throw new Error('Failed to fetch booked hotels');
      }

      const data = await res.json();
      setBookedHotelData(data.data || []); // Ensure data is an array or empty array
    } catch (err) {
      console.error(err);
      setError('Failed to load booked hotels');
    }
  };

  const fetchBookedCars = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const res = await fetch(`${BASE_URL}car_booking_syt/displayUserBookedCar`, {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        throw new Error('Failed to fetch booked cars');
      }

      const data = await res.json();
      setBookedCarData(data.data || []); // Ensure data is an array or empty array
    } catch (err) {
      console.error(err);
      setError('Failed to load booked cars');
    }
  };

  const mergeAndSortData = () => {
    // Check if all three arrays are valid arrays
    const validBookedData = Array.isArray(bookedData) ? bookedData : [];
    const validBookedHotelData = Array.isArray(bookedHotelData) ? bookedHotelData : [];
    const validBookedCarData = Array.isArray(bookedCarData) ? bookedCarData : [];

    // Map each array to a common date key
    const mappedBookedData = validBookedData.map(item => ({ ...item, commonDate: new Date(item.approx_start_date) }));
    const mappedBookedHotelData = validBookedHotelData.map(item => ({ ...item, commonDate: new Date(item.check_in_date) }));
    const mappedBookedCarData = validBookedCarData.map(item => ({ ...item, commonDate: new Date(item.pickup_date) }));

    // Combine all mapped data into a single array
    const combinedData = [...mappedBookedData, ...mappedBookedHotelData, ...mappedBookedCarData];

    // Sort the combined data by the commonDate in descending order
    const sortedData = combinedData.sort((a, b) => b.commonDate - a.commonDate);

    // Remove the commonDate key if it's not needed in the final result
    const finalData = sortedData.map(item => {
      const { commonDate, ...rest } = item;
      return rest;
    });

    // Set the merged and sorted data
    setMergedData(finalData);
  };


  // Fetch data on component mount and update mergedData whenever bookedData or bookedHotelData change
  useEffect(() => {
    const fetchData = async () => {
      await fetchBookedPackage();
      await fetchBookedHotels();
      await fetchBookedCars();
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Merge and sort data when either bookedData or bookedHotelData changes
    mergeAndSortData();
  }, [bookedData, bookedHotelData, bookedCarData]);

  const userToken = localStorage.getItem("userToken")

  useEffect(() => {
    if (!userToken) {
      navigate("/")
    } else {
      // BookedPackage()
      // bookedHotels()
    }
  }, [])

  const handleNavigate = (packageId) => {
    navigate(`/booking-full-detail/${packageId}`)
  }

  const handleHotelNavigate = (packageId) => {
    navigate(`/hotel_booking/${packageId?._id} `, { state: packageId })
  }

  const handleCarNavigate = (e) => {
    navigate(`/booked-car-details/${e?._id} `)
  }
  const handleCancelBooking = (bookingId) => {
    setIsBookingCancel(true)
    setBookingId(bookingId)
  }

  const handleCloseBookingModal = () => {
    setIsBookingCancel(false)
    setBookingId("")
    setReason("")
  }

  const handleChangeReason = (e) => {
    setReason(e.target.value)
    setError("")
  }

  const handleCancelBookingConfirm = () => {
    if (reason === "") {
      setError("Please enter your reason")

      return
    }

    setIsBookingCancel(false)
    setCancelledBookingId([...cancelledBookingId, bookingId])
    setReason("")
    setBookingId("")
  }

  return (
    <>
      <div>
        <Header />
        <section>
          <div className='container-customes px-sm-5 px-2'>
            <div className='pt-3'>
              <h1 className='text-center booked-package-list-media text32 font800'>
                My Bookings
              </h1>
            </div>

            {mergedData.length === 0 && (
              <p className='text-center'>Not a Any Bookings</p>
            )}

            {mergedData &&
              mergedData
                .slice()
                .reverse()
                .map((e) => {
                  const hasUserRegistrationId = e.hasOwnProperty('user_registration_id');
                  return (
                    <React.Fragment key={e._id}>
                      

                      {e.booktype === "package" &&
                        // Render the existing design if `user_registration_id` is present
                        <section className='pt-2 pb-3'>
                          <div>
                            <Row className='mx-auto'>
                              <div className='col-xl-12 col-lg-6 col-sm-12 col-12 m-0 p-0'>
                                <div className="inner_CuStom_package">
                                  <p className='CuStom_package_header text-capitalize text22 font700 py-2'>
                                    {/* {e?.package_details?.[0]?.name} */}
                                  </p>
                                  <div className='booking-list-grid h-100 w-full px-3 py-3'>
                                    <img
                                      src='/C-packega.png'
                                      alt='img'
                                      className='booking-list-header-image'
                                    />
                                    <div className='h-auto w-100'>
                                      <div className='CuStom_package_content my_package_content'>
                                        <div className="d-flex justify-content-between">
                                            <p className='mb-xl-2 mb-lg-0 text14'>
                                              <span className="text18 font600">{e?.package_details?.[0]?.name}</span>
                                            </p>
                                          <div className='text-end mb-2'>
                                            <span
                                              className='text-end'
                                              style={{
                                                color: "white",
                                                padding: "5px 6px",
                                                fontSize: "12px",
                                                borderRadius: "8px",
                                                background: cancelledBookingId.includes(e?._id)
                                                  ? "red"
                                                  : "green",
                                              }}
                                            >
                                              {cancelledBookingId.includes(e?._id)
                                                ? "Booking Cancelled"
                                                : "Booking Confirmed"}
                                            </span>
                                          </div>


                                        </div>

                                        <p className='mb-xl-2 mb-lg-0 text14'>
                                          <span className="text14 font600">Booking Id</span> : {e?._id}
                                        </p>
                                        <p className='mb-xl-2 mb-lg-0 text14'>
                                          <span className="text14 font600">Trip Duration</span> :{" "}
                                          {e?.approx_start_date ? e.approx_start_date.slice(0, 10) : "N/A"}{" "}
                                          to {e?.approx_end_date ? e.approx_end_date.slice(0, 10) : "N/A"}
                                        </p>
                                        <div className='d-flex justify-content-between mb-2 mt-3'>
                                          <button
                                            className='button-9090'
                                            onClick={() => handleNavigate(e?._id)}
                                            style={{ boxShadow: "none" }}
                                          >
                                            View Details
                                          </button>

                                          {!cancelledBookingId.includes(e?._id) ? (
                                            <button
                                              className='cancel_btn'
                                              onClick={() => handleCancelBooking(e?._id)}
                                            >
                                              Cancel
                                            </button>
                                          ) : (
                                            <div />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </section>
                      }
                    </React.Fragment>
                  )
                })}
          </div>
        </section>
      </div>

      <Modal
        show={isBookingCancel}
        onHide={handleCloseBookingModal}
        backdrop='static'
        keyboard={false}
        style={{ zIndex: "10101010" }}
        centered
      >
        <Modal.Body>
          <div className='pt-3 pb-2 px-3'>
            <h5 className='text-center text-capitalize'>
              Why Wan't to cancel your booking?
            </h5>

            <div>
              <p className='mt-3'>Write Your Reason</p>
              <textarea
                name=''
                id=''
                rows='5'
                className='p-2'
                value={reason}
                style={{ width: "100%" }}
                onChange={handleChangeReason}
              />
              {error && <p className='text-danger'>{error}</p>}
            </div>

            <div className='logout-btn d-flex justify-content-center mt-3'>
              <button
                className='logout-btn-1'
                variant='secondary'
                onClick={handleCloseBookingModal}
              >
                No
              </button>
              <button
                className='logout-btn-2 ms-2'
                variant='primary'
                onClick={handleCancelBookingConfirm}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <hr />

      <Footer />
    </>
  )
}

export default My_package
