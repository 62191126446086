import React, { useState, useEffect, useContext } from "react"
import Header from "./Header"
import { Container, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBrush,
  faCircle,
  faCircleCheck,
  faCircleRadiation,
  faCircleXmark,
  faIndianRupeeSign,
  faLayerGroup,
  faStar,
  faTag,
} from "@fortawesome/free-solid-svg-icons"
import { ids } from "../App"
import { bidContext } from "../App"
import { hotelName } from "../App"
import { BASE_URL } from "../BASE_URL"
import Footer from "./Footer"
import DOMPurify from "dompurify"
import { useLocation } from "react-router-dom"

function Compare_packega(props) {

  const location = useLocation();
  const ids = location.state.id;

  const [firstBid, setFirstBid] = useState(ids[0] || "")
  const [secondBid, setSecondBid] = useState(ids[1] || "")

  useEffect(() => {
    if (ids.length > 0) {
      setFirstBid(ids[0])
      setSecondBid(ids[1])
    }
  }, [ids]);

  const { bidData, setBidData } = useContext(bidContext)
  const [bidDetails, setBidDetails] = useState([])
  const [bidDetails1, setBidDetails1] = useState([])
  const { hotel, setHotel } = useContext(hotelName)
  console.log(bidDetails)
  console.log(bidDetails1)


  const DetailsBid = async () => {
    const res = await fetch(`${BASE_URL}bidpackage/biddetails_jaydev?_id=${firstBid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setBidDetails(data?.data?.[0])
  }
  const DetailsBid1 = async () => {
    const res = await fetch(`${BASE_URL}bidpackage/biddetails_jaydev?_id=${secondBid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setBidDetails1(data?.data?.[0])
  }

  useEffect(() => {
    DetailsBid()
    DetailsBid1()
  }, [])

  const itenerary1Length = bidDetails?.itineries?.length;
  const itenerary2Length = bidDetails1?.itineries?.length;

  const [intinerary, setItineraryMap] = useState([])

  useEffect(() => {
    const iteneraryToMap = itenerary1Length >= itenerary2Length ? bidDetails?.itineries : bidDetails1?.itineries;
    setItineraryMap(iteneraryToMap)
  }, [bidDetails && bidDetails1]);



  return (
    <>
      <Header />

      {bidDetails1.length === 0 && bidDetails.length === 0 ? (
        <p>Not a any compare package</p>
      ) : (
        <section className='py-3'>
          <div className='container-fluid px-xl-5 px-lg-4 px-md-3 px-sm-2 '>
            <div bordered>
              <div className='text-center compare_packages_header py-2'>
                <h3 className='cmnp mb-2'>Compare Packages</h3>
                <p style={{ color: "#B8B8B8" }}>
                  Compare packages and find your perfect match!
                </p>
                <h5 style={{ color: "#09646d", fontWeight: "600" }}>{bidDetails.departure} to {bidDetails.destination}</h5>
              </div>
              <div>
                <table className="compare-packages-table" width={"100%"}>
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ textTransform: "capitalize" }}>
                        {hotel[0]}
                      </th>
                      <th>{hotel[1]}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='size-500'>
                        <FontAwesomeIcon icon={faCircleRadiation} /> Trip Duration
                      </td>
                      <td>
                        {bidDetails.start_date1} to {bidDetails.end_date1}
                      </td>
                      <td>
                        {bidDetails1.start_date1} to {bidDetails1.end_date1}
                      </td>
                      {/* <td>22/08/2023 to 26/08/2023</td> */}
                    </tr>
                    <tr>
                      <td className='size-500'>Total Days/Nights </td>
                      <td>{bidDetails?.total_days}/{bidDetails?.total_nights}</td>
                      <td>{bidDetails1?.total_days}/{bidDetails1?.total_nights}</td>
                    </tr>
                    <tr>
                      <td className='size-500'>
                        <FontAwesomeIcon icon={faTag} /> Price Per Person
                      </td>
                      <td style={{ color: "#00B707" }}>
                        <p>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {bidDetails.price_per_person_adult}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Adults({bidDetails?.total_adult}))</span>
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {bidDetails.price_per_person_child}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Childrens({bidDetails?.total_child}))</span>
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {bidDetails.price_per_person_infant}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Childrens({bidDetails?.Infant}))</span>
                        </p>
                      </td>
                      <td style={{ color: "#00B707" }}>
                        <p>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {bidDetails1.price_per_person_adult}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Adults({bidDetails1?.total_adult}))</span>
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {bidDetails1.price_per_person_child}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Childrens({bidDetails1?.total_child}))</span>
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                          {bidDetails1.price_per_person_infant}{" "}
                          <span style={{ color: "#B8B8B8" }}>| per person (Infants({bidDetails1?.Infant}))</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>
                        <FontAwesomeIcon icon={faTag} /> Total
                      </td>
                      <td style={{ color: "#00B707" }}>
                        <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                        {bidDetails?.total_amount}
                      </td>
                      <td style={{ color: "#00B707" }}>
                        <FontAwesomeIcon icon={faIndianRupeeSign} />{" "}
                        {bidDetails1?.total_amount}
                      </td>
                    </tr>
                    <tr>
                      <td colspan='3' style={{ background: "#09646D" }}>
                        <p className='w-100 text-white cmnp ps-2 py-2'>
                          <FontAwesomeIcon icon={faLayerGroup} /> Overview
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>Total Reviews</td>
                      <td>
                        <FontAwesomeIcon icon={faStar} className='c_icon' />
                        <FontAwesomeIcon icon={faStar} className='c_icon' />
                        <FontAwesomeIcon icon={faStar} className='c_icon' />
                        <FontAwesomeIcon icon={faStar} className='c_icon' />
                        <FontAwesomeIcon
                          icon={faStar}
                          className=''
                          style={{ color: "#B8B8B8" }}
                        />{" "}
                        | 11,000(static)
                        <span style={{ color: "#B8B8B8" }}>(5)</span>
                      </td>
                      <td>
                        <FontAwesomeIcon icon={faStar} className='c_icon' />
                        <FontAwesomeIcon icon={faStar} className='c_icon' />
                        <FontAwesomeIcon icon={faStar} className='c_icon' />
                        <FontAwesomeIcon icon={faStar} className='c_icon' />
                        <FontAwesomeIcon
                          icon={faStar}
                          className=''
                          style={{ color: "#B8B8B8" }}
                        />{" "}
                        | 11,000(static)
                        <span style={{ color: "#B8B8B8" }}>(5)</span>
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>Meals</td>
                      <td>
                        {bidDetails.meal_required}
                        {/* Include */}
                      </td>
                      <td>
                        {bidDetails1?.meal_required}
                        {/* Include */}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>Meals Type</td>
                      <td>
                        {bidDetails.meal_types}
                        {/* Include */}
                      </td>
                      <td>
                        {bidDetails1?.meal_types}
                        {/* Include */}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>Sightseeing</td>
                      <td>{bidDetails.sightseeing}</td>
                      <td>{bidDetails1.sightseeing}</td>
                    </tr>
                    <tr>
                      <td className='size-500'>Travel By</td>
                      <td>{bidDetails.travel_by}</td>
                      <td>{bidDetails1.travel_by}</td>
                    </tr>
                    <tr>
                      <td className='size-500'>Categories</td>
                      <td>
                        {bidDetails?.destination_category_name?.length > 0 && bidDetails?.destination_category_name.map(category => category.category_name).join(", ")}
                      </td>
                      <td>
                        {bidDetails1?.destination_category_name?.length > 0 && bidDetails1?.destination_category_name.map(category => category.category_name).join(", ")}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>Room Sharing</td>
                      <td>
                        {bidDetails?.room_sharing}
                      </td>
                      <td>
                        {bidDetails1?.room_sharing}
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>Other Requirements </td>
                      <td>{bidDetails?.additional_requirement}</td>
                      <td>{bidDetails1?.additional_requirement}</td>
                    </tr>
                    <tr>
                      <td className='size-500'>Personal Care </td>
                      <td>{bidDetails?.personal_care}</td>
                      <td>{bidDetails1?.personal_care}</td>
                    </tr>
                    <tr>
                      <td colspan='3' style={{ background: "#09646D" }}>
                        <p className='w-100 text-white cmnp ps-2 py-2'>
                          <FontAwesomeIcon icon={faBrush} /> Services
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>
                        <FontAwesomeIcon icon={faCircleCheck} /> Included
                      </td>
                      <td>
                        <ul style={{ color: "#00B707" }}>
                          {bidDetails.include_services &&
                            bidDetails.include_services.map((ele) => {
                              return (
                                <>
                                  <li
                                    key={ele._id}
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        ele.include_services_value,
                                      ),
                                    }}
                                  />
                                </>
                              )
                            })}
                        </ul>
                      </td>
                      <td>
                        <ul style={{ color: "#00B707" }}>
                          {bidDetails1.include_services &&
                            bidDetails1.include_services.map((ele) => {
                              return (
                                <>
                                  <li
                                    key={ele._id}
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        ele.include_services_value,
                                      ),
                                    }}
                                  />
                                </>
                              )
                            })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className='size-500'>
                        <FontAwesomeIcon icon={faCircleXmark} /> Excluded
                      </td>
                      <td>
                        <ul style={{ color: "#E50000" }}>
                          {bidDetails?.exclude_services &&
                            bidDetails?.exclude_services.map((ele) => {
                              return (
                                <>
                                  <li
                                    key={ele._id}
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        ele.exclude_services_value,
                                      ),
                                    }}
                                  />
                                </>
                              )
                            })}
                        </ul>
                      </td>
                      <td>
                        <ul style={{ color: "#E50000" }}>
                          {bidDetails?.exclude_services && bidDetails1?.exclude_services?.map((ele) => {
                            return (
                              <>
                                <li
                                  key={ele._id}
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      ele.exclude_services_value,
                                    ),
                                  }}
                                />
                              </>
                            )
                          })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td colspan='3' style={{ background: "#09646D" }}>
                        <p className='w-100 text-white cmnp ps-2 py-2'>
                          <img
                            src='/vector1.png'
                            className='img-fluid'
                            alt='vector'
                          />
                          Itinerary
                        </p>
                      </td>
                    </tr>

                    {intinerary && intinerary.map((item, index) => (
                      <tr key={index}>
                        <td className='size-500'>Day {index + 1}</td>
                        <td>
                          <div>
                            <p><b>Title: </b>{bidDetails?.itineries?.length > 0 && bidDetails?.itineries[index]?.title}</p>
                          </div>
                          <div>
                            <p><b>Hotel Name: </b>{bidDetails?.itineries?.length > 0 && bidDetails?.itineries[index]?.hotel_name}</p>
                          </div>
                          <div>
                            <p><b>Description: </b>{bidDetails?.itineries?.length > 0 && bidDetails?.itineries[index]?.activity}</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p><b>Title: </b>{bidDetails1?.itineries?.length > 0 && bidDetails1?.itineries[index]?.title}</p>
                          </div>
                          <div>
                            <p><b>Hotel Name: </b>{bidDetails1?.itineries?.length > 0 && bidDetails1?.itineries[index]?.hotel_name}</p>
                          </div>
                          <div>
                            <p><b>Description: </b>{bidDetails1?.itineries?.length > 0 && bidDetails1?.itineries[index]?.activity}</p>
                          </div>
                        </td>
                      </tr>
                    ))}


                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      )}

      <hr />
      <Footer />
    </>
  )
}

export default Compare_packega
