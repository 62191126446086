import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import "./css/Hotal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign, faStar, faLeftLong, faRightLong, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../BASE_URL";
import Footer from "./Footer";
import axios from "axios";
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper"
import BookingModal from "./booking-hotel-modal/BookingHotelModal";
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Rating from "@mui/material/Rating"
import { Modal, Row } from "react-bootstrap"
import Carousel from 'react-bootstrap/Carousel';

function Hotel_Booking(props) {

  const { id } = useParams();

  const navigate = useNavigate()

  const [hotelDetail, setHotelDetail] = useState([]);
  const [propertyDetail, setPropertyDetail] = useState('');
  const [amenities, setAmenities] = useState([]);

  const [hotelName, setHotelName] = useState('')
  const [checkIn, setCheckIn] = useState('')
  const [checkOut, setCheckOut] = useState('')
  const [adults, setAdults] = useState('')
  const [children, setChildren] = useState('')
  const [infants, setInfants] = useState('')

  const [showButton, setShowButton] = useState(false)
  const [hotelId, setHotelId] = useState('');


  const hotelList = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}hotel_syt/details?_id=${hotelId}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    setHotelDetail(data?.data?.[0]);
    setPropertyDetail(data?.data?.[0]?.property_policies[0])
    setAmenities(data?.data?.[0]?.amenities_and_facilities)
    const myId = localStorage.getItem('my_id')


    if (data?.data?.[0]?.hotel_reviews) {
      const reviews = data.data[0].hotel_reviews;

      // Check if any review's user_details._id matches myId
      const hasMatchingId = reviews.some(review => review.user_details._id === myId);

      if (!hasMatchingId) {
        // No match found, setShowButton to true
        setShowButton(true);
      } else {
        // Match found, setShowButton to false
        setShowButton(false);
      }
    } else {
      // Handle cases where data or hotel_reviews might be undefined or empty
      setShowButton(true);
    }
  };

  const [roomData, setRoomData] = useState([])

  const [alreadyRoomName, setAlreadyRoomName] = useState('');
  const [alreadyCheckIn, setAlreadyCheckIn] = useState('');
  const [alreadyCheckOut, setAlreadyCheckOut] = useState('');
  const [alreadyAdults, setAlreadyAdults] = useState(0);
  const [alreadyChildren, setAlreadyChildren] = useState(0);
  const [alreadyTotalRooms, setAlreadyTotalRooms] = useState(0);
  const [alreadyData, setAlreadyData] = useState(null);

  useEffect(() => {
    hotelbookingDetail();
  }, []);

  const hotelbookingDetail = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}hotel_booking_syt/hotel_book_details/${id}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    const booking = data?.data?.[0];

    if (booking) {
      setAlreadyRoomName(booking.room_title);
      setAlreadyCheckIn(new Date(booking.check_in_date).toISOString().split('T')[0]);
      setAlreadyCheckOut(new Date(booking.check_out_date).toISOString().split('T')[0]);
      setAlreadyAdults(booking.total_adult);
      setAlreadyChildren(booking.total_child);
      setAlreadyTotalRooms(booking.total_booked_rooms);
      setAlreadyData(booking);
      setHotelId(booking.hotel_details[0]._id)
    }
  };


  useEffect(() => {
    if (hotelId) {
      hotelList();
    }
  }, [hotelId]);

  useEffect(() => {
    hotelbookingDetail();
  }, []);


  const overRef = useRef(null);
  const roomsRef = useRef(null);
  const facilities = useRef(null);
  const location = useRef(null);
  const policies = useRef(null);
  const reviewRef = useRef(null);


  const scrollTo = (section) => {
    let targetRef;

    switch (section) {
      case 'overview':
        targetRef = overRef;
        break;
      case 'rooms':
        targetRef = roomsRef;
        break;
      case 'facilities':
        targetRef = facilities;
        break;
      case 'location':
        targetRef = location;
        break;
      case 'policies':
        targetRef = policies;
        break;
      case 'review':
        targetRef = review;
        break;
      default:
        targetRef = null;
    }

    if (targetRef) {
      window.scroll({
        top: targetRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 0);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [googleMapsEmbedUrl, setGoogleMapsEmbedUrl] = useState('');

  useEffect(() => {
    // Function to fetch coordinates based on the address
    const fetchCoordinates = async (address) => {
      try {
        const apiKey = 'YOUR_GOOGLE_MAPS_API_KEY'; // Replace with your actual API key
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

        const response = await axios.get(geocodeUrl);

        if (response.data.status === 'OK') {
          const location = response.data.results[0].geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;

          // Construct the embed URL with the obtained coordinates
          const embedUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${latitude},${longitude}`;
          setGoogleMapsEmbedUrl(embedUrl);
        } else {
          console.error('Geocode was not successful for the following reason:', response.data.status);
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
    };

    const myLocation = hotelDetail.hotel_name + "," + hotelDetail.hotel_address

    if (myLocation) {
      fetchCoordinates(myLocation);
    }
  }, [hotelDetail]);


  const totalStars = 5;




  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    if (e) {
      setRating(e?.star?.slice(0, 1))
      setComment(e?.comment_box)
      setReviewId(e?._id)
    }
  }

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => {
    setShow2(true);
  }
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState("");

  const [review, setReview] = useState(null);
  const [reviewid, setReviewId] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const handleRatingChange = (event) => {
    setRating(event.target.value);
    setIsUpdated(true); // Enable update button when rating changes
  };

  const fetchReview = async () => {
    const token = localStorage.getItem("userToken");
    const res = await fetch(`${BASE_URL}review?book_package_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("userToken");
    if (!rating || !comment) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const res = await fetch(`${BASE_URL}hotel_review/add_review`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        book_hotel_id: id,
        star: rating,
        comment_box: comment,
      }),
    });

    const data = await res.json();
    handleClose();

    if (data.code === 200) {
      hotelList()
      toast.success("Review submitted successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      setReview(data.data); // Update state with the new review
      setIsUpdated(false);
      fetchReview();
    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("userToken");

    if (!comment || !rating) {
      toast.error("Please fill all details!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const res = await fetch(`${BASE_URL}hotel_review/updatereview?review_id=${reviewid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        book_hotel_id: id,
        star: rating,
        comment_box: comment,
      }),
    });

    const data = await res.json();
    handleClose();
    hotelList()
    if (data.code === 200) {
      toast.success("Review updated successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
      setReview(data.data); // Update state with the updated review // Reset the update state
      setIsUpdated(false); // Reset the update state
      fetchReview();

    } else {
      toast.error(data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleCancel = async () => {
    try {
      const token = localStorage.getItem("userToken");
      const res = await fetch(`${BASE_URL}hotel_booking_syt/cancel_status_by_user/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      const data = await res.json();
      handleClose2()
      navigate(-1)
      toast.success("Booking Cancel successfully!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    } catch (error) {
      
    }
  }


  const [myArray, setMyArray] = useState([])
  const [reviewAverage, setReviewAverage] = useState(0);

  const myId = localStorage.getItem('my_id')





  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);



  return (
    <>
      <Header />

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="py-4 px-3">
            <div>
              <div className="d-flex" style={{ gap: "15px" }}>
                <h5>Ratings</h5>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(e) => {
                    handleRatingChange(e);
                  }}
                />
              </div>
            </div>
            <div className="mt-3 mb-3">
              <h5>Comment</h5>
              <div>
                <textarea
                  name=""
                  id=""
                  cols="50"
                  rows="5"
                  className="ps-2 py-2"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                    setIsUpdated(true); // Enable update button when comment changes
                  }}
                ></textarea>
              </div>
            </div>
            <div className="d-flex justify-content-center" style={{ gap: "10px" }}>
              <button onClick={handleClose} className="button-251">
                Close
              </button>
              {reviewid ? (
                <button
                  onClick={handleUpdate}
                  className="button-25"
                  disabled={!isUpdated}
                >
                  Update
                </button>
              ) : (
                <button onClick={handleSubmit} className="button-25">
                  Submit
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="px-3">

            <div className="mt-3 mb-3">
              <h5 style={{ fontWeight: "600" }}>Give Reason Of Cancel Booking?</h5>
              <textarea
                name=""
                id=""
                cols="50"
                rows="5"
                className="ps-2 py-2"
              ></textarea>
            </div>
            <div className="d-flex justify-content-center" style={{ gap: "10px" }}>
              <button onClick={handleClose2} className="button-251">
                Close
              </button>
              <button onClick={handleCancel} className="button-25">
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show3}
        onHide={handleClose3}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="px-3">

            <div className="mt-3 mb-3">
              <img src="" alt="" />
            </div>
            <div className="d-flex justify-content-center" style={{ gap: "10px" }}>
              <button onClick={handleClose3} className="button-251">
                Close
              </button>
              <button onClick={handleCancel} className="button-25">
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*  <!----------------------------------------------- image section ---------------------------------------------> */}
      {hotelDetail && <div>
        <section class="pt-5">
          <div class="container-customes">
            <div class="row text-center">
              <div className="col-8">
                <div className="row h-100">
                  <div class="col-5 h-100">
                    <div class="header-images sea-image h-100">
                      <img src={"/Rectangle 421.png"} alt="" class="h-100" />
                    </div>
                  </div>
                  <div class="col-7 h-100">
                    <div class="row gy-1 h-50">
                      <div class="col-6 h-100">
                        <div className="h-100">
                          <img src="/Rectangle 429.png" alt="" class="h-100" />
                        </div>
                      </div>
                      <div class="col-6 h-100">
                        <div className="h-100">
                          <img src="/Rectangle 425.png" alt="" class="h-100" />
                        </div>
                      </div>
                      <div class="col-6 h-100">
                        <div className="h-100">
                          <img src="/Rectangle 427.png" alt="" class="h-100" />
                        </div>
                      </div>
                      <div class="col-6 h-100">
                        <div className="h-100">
                          <img src="/Rectangle 428.png" alt="" class="h-100" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="availibility">

                  <input
                    type="text"
                    value={alreadyRoomName}
                    name=""
                    id=""
                    className="text-center mb-3"
                    readOnly
                  />
                  <div className="d-flex align-items-center mb-3">
                    <label htmlFor="text" style={{ width: "55%" }}>
                      Check-In date :
                    </label>
                    <input
                      type="date"
                      value={alreadyCheckIn}
                      name=""
                      id=""
                      className="px-2"
                      readOnly
                    />
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <label htmlFor="text" style={{ width: "55%" }}>
                      Check-Out date :
                    </label>
                    <input
                      type="date"
                      value={alreadyCheckOut}
                      name=""
                      id=""
                      className="px-2"
                      readOnly
                    />
                  </div>
                  <div className="row text-center mb-4">
                    <div className="col-4">
                      <label htmlFor="select">Adult</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={alreadyAdults}
                        readOnly
                      >
                        {[...Array(11).keys()].map((num) => (
                          <option key={num} value={num}>{num}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-4">
                      <label htmlFor="select">Children</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={alreadyChildren}
                        readOnly
                      >
                        {[...Array(11).keys()].map((num) => (
                          <option key={num} value={num}>{num}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-4">
                      <label htmlFor="select">Total Rooms</label>
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        value={alreadyTotalRooms}
                        readOnly
                      >
                        {[...Array(11).keys()].map((num) => (
                          <option key={num} value={num}>{num}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div><strong>Payment Type:</strong> {alreadyData?.payment_type}</div>
                    <div><strong>Status:</strong> {alreadyData?.status}</div>
                    <div><strong>Price:</strong> {alreadyData?.price}</div>
                    <div><strong>User Name:</strong> {alreadyData?.user_name}</div>
                    <div><strong>Gender:</strong> {alreadyData?.gender}</div>
                    <div><strong>Country:</strong> {alreadyData?.country}</div>
                    <div><strong>State:</strong> {alreadyData?.state}</div>
                    <div><strong>City:</strong> {alreadyData?.city}</div>
                    <div><strong>Contact No:</strong> {alreadyData?.contact_no}</div>
                    <div><strong>Tranjection Id:</strong> {alreadyData?.transaction_details?.[0]?.trnsaction_id}</div>
                  </div>

                  <div>
                    <div className="d-flex justify-content-center gap-2">
                      {alreadyData?.status === "cancel" ? (
                        <div className="available-search mb-2">
                          <button>{alreadyData?.status}</button>
                        </div>
                      ) : (
                        <div className="available-booked mb-2">
                          <button>{alreadyData?.status}</button>
                        </div>
                      )}
                      {alreadyData?.status === "cancel" ? (
                        <div className="available-booked mb-2">
                          <button>Book Again</button>
                        </div>
                      ) : (
                        // <div className="available-search mb-2">
                        <button onClick={handleShow2} className="available-search">Cancel</button>
                        // </div>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={` whyso ${isSticky ? 'sticky' : ''}`}>
          <div class="container-customes stick-link-bar">
            <div class={`row  rounded px-4 pb-1 pt-2 text-center gx-2 ${isSticky ? 'hshshs' : ''}`}>
              <div class="col-6 Overview">
                <div class="d-flex justify-content-between align-items-center">
                  <a onClick={() => scrollTo('overview')} style={{ cursor: "pointer" }}>Overview</a>
                  <a onClick={() => scrollTo('rooms')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                    Rooms{" "}
                  </a>
                  <a onClick={() => scrollTo('facilities')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                    Facilities
                  </a>
                  <a onClick={() => scrollTo('location')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                    Location
                  </a>
                  <a onClick={() => scrollTo('policies')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                    Policies
                  </a>
                  <a onClick={() => scrollTo('review')} style={{ cursor: "pointer" }} class="d-lg-block d-md-none d-none">
                    Review
                  </a>
                </div>
              </div>
              <div class="col-6 rupees text-end">
                <span>from</span>
                <h4 class="d-inline ms-1">
                  <FontAwesomeIcon icon={faIndianRupeeSign} /> {hotelDetail.rooms === undefined || hotelDetail.rooms?.length === 0 ? "N/A" : Math.min(...hotelDetail.rooms.map(ele => ele.price))}
                </h4>
              </div>
            </div>
          </div>
        </section>

        {/* <!---------------------------------------- center ---------------------------------------------> */}

        <section ref={overRef} className={`${isSticky ? 'overrefcss' : ''}`}>
          <div class="container-customes" >
            <div class="row gx-3" >
              <div class="col-xl-12 col-lg-8 col-md-12 col-sm-12">
                <div class="rose-valley" >
                  <div>
                    <div>
                      <div class="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
                        <div class="me-4">
                          <h4 class="m-0 text-start">{hotelDetail.hotel_name}</h4>
                        </div>
                        <div class="d-flex">
                          {[...Array(totalStars)].map((_, index) => {
                            return (
                              <FontAwesomeIcon
                                key={index}
                                icon={faStar}
                                style={{ color: index < reviewAverage ? 'yellow' : '#DDDDDD' }}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <p class="mt-2 pb-3 border-bot">
                      {hotelDetail.hotel_address},{hotelDetail.city},{hotelDetail.state}
                    </p>
                  </div>
                  <div class="rose-paragraph">
                    <p>{hotelDetail.hotel_description}</p>
                  </div>
                  <div>
                    <div>Other Details</div>
                    <ul>
                      {hotelDetail.other?.map(e => (
                        <li>{e}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div class="highlights my-4">
                  <div class="me-4 mb-4">
                    <h4 class="m-0">Highlights</h4>
                  </div>
                  <div class="row text-center">
                    {hotelDetail?.Highlights && hotelDetail?.Highlights.map((e) => (
                      <div className="col-2">
                        <img src={e.icon} alt="" class="mb-2" style={{ height: "40px", width: "40px" }} />
                        <p>{e.title}</p>
                      </div>
                    ))}
                  </div>
                </div>


                <div class="me-4 mb-4">
                  <h4 class="m-0">Booked Rooms</h4>
                </div>
                {roomData.length !== 0 && <div ref={roomsRef} class="delux-room" style={{ display: "flex", flexWrap: "wrap" }} >
                  {roomData.length !== 0 &&
                    roomData.map((ele) => {
                      return (
                        <div >
                          <div style={{ border: "1px solid black", padding: "10px", display: "flex", borderRadius: "20px" }}>
                            <div>
                              <Carousel style={{ height: "200px", width: "300px" }}>
                                {ele.photos.map((e, index) => (
                                  <Carousel.Item key={index} interval={2000} >
                                    <img src={e} alt={`Slide ${index}`} className="inner-rooms-image" />
                                  </Carousel.Item>
                                ))}
                              </Carousel>
                            </div>
                            <div >
                              <div className="facilities">
                                <h5>
                                  {ele.room_title}
                                </h5>
                                <div>
                                  <ul className="mb-1 row">
                                    {ele?.room_highlights?.map((elem) => (
                                      <li className="col-7" key={elem}>{elem}</li>
                                    ))}
                                  </ul>
                                </div>
                                <div>
                                  <h2 className="mb-xl-3 mb-lg-3 mb-md-2 mb-sm-1 mb-1">
                                    <FontAwesomeIcon icon={faIndianRupeeSign} />
                                    {ele.price} <span>per night</span>
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>}

                {/* amenities  */}

                <div class="amenities my-4" ref={facilities}>
                  <h5 class="ps-2">Amenities and facilities</h5>
                  <div class="row px-4">
                    {amenities && amenities.map((e) => (
                      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12 col-12 antiviral">
                        <h6>{e.title}</h6>
                        <ul class="mb-1">
                          {e.points.map((data) => (
                            <li class="mb-1">{data}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>

        {/* <!---------------------------------------- map -------------------------------------------> */}

        <section class="py-4" ref={location}>
          <div class="container-customes">
            <div class="row">
              <div class="col-12">
                <h4>Location</h4>
                <iframe
                  src={googleMapsEmbedUrl}
                  width="100%"
                  height="450"
                  style={{ borderRadius: "10px" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        {/* <!------------------------------- footer ----------------------------------------> */}

        <section class="my-4 pb-4" ref={policies}>
          <div class="container-customes">
            <div class="row foot-center">
              <div class="col footer">
                <div class="foot">
                  <div class="footer-head">
                    <h5 class="mb-5">Property policies</h5>
                    <h6 class="mb-3">{propertyDetail?.policy_title}</h6>
                    <p>
                      {propertyDetail?.policy_description}
                    </p>
                  </div>
                  <div class="row gx-3">
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                      <div class="box">
                        <div class="mb-3">
                          <h5 class="pb-2">Infant 0-2 year</h5>
                        </div>
                        <div>
                          <h6 class="mb-3">
                            {propertyDetail?.infant}
                          </h6>
                          {propertyDetail && propertyDetail?.infant_points?.map((e) => (
                            <p>{e}</p>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                      <div class="box">
                        <div class="mb-3">
                          <h5 class="pb-2">Children 3-11 year</h5>
                        </div>
                        <div class="pb-3">
                          <h6 class="mb-3">{propertyDetail?.children}</h6>
                          {propertyDetail && propertyDetail?.childern_points?.map((e) => (
                            <p>{e}</p>))}
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 pt-3">
                      <div class="box">
                        <div class="mb-3">
                          <h5 class="pb-2">Adults 12 & above</h5>
                        </div>
                        <div class="pb-5">
                          <h6 class="mb-3">{propertyDetail?.infant}</h6>
                          {propertyDetail && propertyDetail?.infant_points?.map((e) => (
                            <p>{e}</p>))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="others mt-3">
                    <h5>Others</h5>
                    <ul>
                      {propertyDetail && propertyDetail?.policy_other.map((e) => (
                        <li>
                          {e}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="my-4 pb-4" ref={reviewRef}>
          <div class="container-customes">
            <div class="row foot-center">
              <div class="col footer">
                <div class="foot">
                  <div class="footer-head hotel-review-box">
                    <div>
                      <h5 className="mb-1">Reviews</h5>
                      <div className="d-flex align-items-center gap-2">
                        {[...Array(totalStars)].map((_, index) => {
                          return (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              style={{ color: index < reviewAverage ? 'yellow' : '#DDDDDD' }}
                            />
                          );
                        })}
                        {reviewAverage}
                      </div>
                    </div>
                    {showButton && (
                      <div className="insert-hotel-review">
                        <button onClick={handleShow}>Add Review</button>
                      </div>
                    )}
                  </div>
                  <hr className="mb-5" />
                  <div className="hotel-riviews">
                    {myArray && myArray?.map((e) => (
                      <div className="hotel-review-specific">
                        <div className="personal-review">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex hotel-review-header">
                              <div>
                                <img src="" alt="" />
                              </div>
                              <div>
                                <h5 className="mb-1">{e?.user_details?.name}</h5>
                                <p className="mb-0">{e?.createdAt?.slice(0, 10)}</p>
                              </div>
                            </div>
                            <div>
                              <Rating
                                name="simple-controlled"
                                value={parseInt(e.star)}
                                readOnly
                              />
                            </div>
                          </div>
                          <hr />
                          <div>
                            <p>{e?.comment_box}</p>
                          </div>
                        </div>
                        {e?.user_details?._id === myId && (
                          <div className="hotel-review-edit" onClick={() => handleShow(e)}>
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>}
      <hr />


      <Footer />
    </>
  );
}

export default Hotel_Booking;
