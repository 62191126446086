import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Header2 from "./Header2";
import My_pannel from "./My_pannel";
import { day } from "../App";
import { BASE_URL } from "../BASE_URL";
import loader from "../images/loading.png";

const AddItineriesEdit = () => {
  const { id, BidId } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const custid = location.state.customrid;
  const { dayNumber } = useContext(day);
  const [showModal, setShowModal] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState(null);

  const [itineriesData, setItineriesData] = useState({
    day: "",
    title: "",
    photo: "",
    activity: "",
  });

  const [editorData, setEditorData] = useState('');
  const [photoPreview, setPhotoPreview] = useState(null);  // State for photo preview

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  const txt = (e) => {
    const { name, value } = e.target;
    setItineriesData({ ...itineriesData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setItineriesData({ ...itineriesData, photo: file });
      setPhotoPreview(URL.createObjectURL(file));  // Set the preview URL
    }
  };

  const getItinerary = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}itinerary/byid?_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });
    const data = await res.json();
    const allData = data?.data?.[0];
    setItineriesData(data.data);
    setSelectedHotel(data.data?.[0]?.hotel_itienrary?.[0]);
    setEditorData(data?.data?.[0]?.activity);
    setPhotoPreview(data?.data?.[0]?.photo);
    setItineriesData({
      ...itineriesData,
      day: allData?.day,
      title: allData?.title,
      photo: allData?.photo
    });
  };

  const AddItineriesDataEdit = async () => {
    const token = localStorage.getItem("vendorToken");
    const formData = new FormData();
    formData.append("day", itineriesData.day);
    formData.append("title", itineriesData.title);
    formData.append("activity", editorData);
    formData.append("hotel_itienrary_id", selectedHotel?._id);
    if (itineriesData.photo) {
      formData.append("photo", itineriesData.photo);
    }
    setLoading(true);
    const res = await fetch(
      `${BASE_URL}itinerary/biditinerary?bid_id=${BidId}&day=${dayNumber}`,
      {
        method: "PUT",
        headers: {
          Authorization: token,
        },
        body: formData,
      }
    );
    if (res.status === 200) {
      setLoading(false);
      navigate(`/vendor/Submit_package_form/${custid}`);
    } else {
      setLoading(false);
    }
  };

  const fetchItineraryHotel = async () => {
    const token = localStorage.getItem("vendorToken");
    const res = await fetch(`${BASE_URL}api/hotel_itienrary/displayAgencyById`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
    const data = await res.json();
    setHotelList(data?.data);
  };

  useEffect(() => {
    getItinerary();
    fetchItineraryHotel();
  }, [id]);

  return (
    <>
      <Header2 />
      <div className='costum_container'>
        <My_pannel />
        <section className='vendor_submit_packages'>
          <div className='costum_container'>
            <div className='p-3'>
              <div>
                <Row className='itinerary_padding green_border gy-2 gx-5 margin_left_right justify-content-center' style={{ backgroundColor: "#ffffff" }}>
                  <div className='col-12 mb-3'>
                    <div className='row'>
                      <div className='col-12'>
                        <span className='text-20 itinerary_header'>
                          Day wise Itinerary plan
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row d-flex justify-content-center'>
                    <div className='col-12' style={{ border: "1px solid grey", borderRadius: "15px" }}>
                      <div className='p-3'>
                        <div>
                          <div className='mb-2'>
                            <label htmlFor='text'>Day</label>
                            <input
                              type='text'
                              name='day'
                              onChange={txt}
                              value={itineriesData.day}
                              readOnly
                            />
                          </div>
                          <div className='mb-2'>
                            <label htmlFor='text'>Update Title</label>
                            <input
                              type='text'
                              name='title'
                              onChange={txt}
                              value={itineriesData.title}
                            />
                          </div>
                          <div className='mb-2'>
                            <label htmlFor='text'>Update Photo</label>
                            <input type='file' name='photo' onChange={handlePhotoChange} />
                            {photoPreview && (
                              <div style={{ marginTop: "10px" }}>
                                <img
                                  src={photoPreview}
                                  alt="Selected"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    borderRadius: "50%",
                                    objectFit: "cover"
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className='mb-2'>
                            <label htmlFor='text'>Update Hotel</label>
                            <div className='d-flex align-items-center'>
                              <input
                                type='text'
                                name='select_hotel'
                                onChange={txt}
                                className='flex-grow-1'
                                value={selectedHotel ? selectedHotel.hotel_name : ""}
                                readOnly
                              />
                              <Button
                                variant='primary'
                                className='ms-2'
                                onClick={() => setShowModal(true)}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                          <div className='mb-2'>
                            <label htmlFor='text'>Update Description/Activity</label>
                            <CKEditor
                              editor={ClassicEditor}
                              data={editorData}
                              onChange={handleEditorChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        {loading ? (
                          <button
                            className='m-3'
                            style={{
                              width: "160px",
                              height: "40px",
                              backgroundColor: "#09646d",
                              border: "none",
                              color: "white",
                            }}
                          >
                            <img src={loader} alt="" className="h-75 spinner" />
                          </button>
                        ) : (
                          <button
                            className='m-3'
                            style={{
                              width: "160px",
                              height: "40px",
                              backgroundColor: "#09646d",
                              border: "none",
                              color: "white",
                            }}
                            onClick={AddItineriesDataEdit}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>

                {/* Modal */}
                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                  <Modal.Body
                    style={{
                      maxHeight: '70vh',    // Sets the maximum height to 70% of the viewport height
                      overflowY: 'auto'     // Enables vertical scrolling
                    }}
                  >
                    <div>
                      <h5 className="mb-0 mt-2 ms-2">Select Hotel</h5>
                      <hr />
                      {hotelList && hotelList.map((e, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-start mb-3 mx-4 gap-3 itinerary-hotel-select-list"
                        >
                          <input
                            type="radio"
                            name="hotel"
                            className="form-check-input mb-1"
                            id={e.hotel_name}
                            checked={selectedHotel && selectedHotel._id === e._id}
                            onChange={() => {
                              setSelectedHotel(e);
                              setShowModal(false);
                            }}
                          />
                          <label
                            htmlFor={e.hotel_name}
                            className="form-check-label"
                          >
                            {e.hotel_name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddItineriesEdit;
