import React, { useEffect, useState } from 'react';
import './BookingModal.css';
import { BASE_URL } from '../../BASE_URL';
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";
import countries from "../../CountryStateCity.json"
import { useLocation, useParams, useNavigate } from "react-router-dom"


const BookingModal = ({ isOpen, onClose, hotelId, roomId, checkindate, checkoutdate, totalRooms, alreadyAdults, alreadyChildren }) => {
    const location = useLocation();
    const { merchentId } = useParams();
    const navigate = useNavigate();

    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [username, setUserName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [gender, setGender] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [dob, setDob] = useState(null);
    const [paymentType, setPaymentType] = useState('');

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        setCountry(selectedCountry)
        const selectedStates = countries.find((e) => e.name === selectedCountry)
        setStates(selectedStates?.states)
    }

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        setState(selectedState)
        const selectedCities = states.find((e) => e.name === selectedState)
        setCities(selectedCities?.cities)
    }


    const userDetails = async () => {
        const token = localStorage.getItem("token");
        const res = await fetch(
            `${BASE_URL}payment/payment_status?merchantTransactionId=${merchentId}`,
            {
                method: "GET",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await res.json();

        const bookingDetails = JSON.parse(localStorage.getItem("bookingDetail"));

        const updatedbookingDetails =
        {
            ...bookingDetails,
            transaction_id: data?.data?.merchantTransactionId,
            payment_type: data?.data?.paymentInstrument?.type,
        }


        if (bookingDetails !== "") {
            localStorage.removeItem("bookingDetail")
            try {
                const token = localStorage.getItem("userToken");
                const response = await fetch(`${BASE_URL}hotel_booking_syt`, {
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatedbookingDetails),
                });


                const data = await response.json();
                
                if (data.code === 200) {
                    navigate("/my_package")
                    toast.success("Hotel Booked Successfully!", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 1000,
                    })
                }
            }
            catch (error) {
                toast.error("Failed to book the hotel!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1000,
                })
            }
        }

    };

    useEffect(() => {
        if (merchentId) {
            userDetails();
        }
    }, [merchentId]);


    if (!isOpen) return null;

    const handlePrice = async () => {

        if (!totalRooms) {
            toast.error("Please Select Total Rooms!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            })
            return;
        }


        if (!paymentType) {
            toast.error("Please Select Payment Type!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
            })
            return;
        }

        const myId = localStorage.getItem("my_id")
        const mobileno = contactNumber;
        const amount = Number(roomId?.price) * (Number(alreadyAdults) + Number(alreadyChildren))
        // const amount = 1;
        const url = location.pathname;
        const token = localStorage.getItem("userToken")

        const res = await fetch(`${BASE_URL}payment/pay`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
            body: JSON.stringify({
                MERCHANT_USER_ID: myId,
                amount: amount,
                mobileNumber: mobileno,
                link: url,
                hotel_id: hotelId,
                room_id: roomId?._id
            }),
        });

        const data = await res.json();

        const bookingData = {
            hotel_id: hotelId,
            room_id: roomId._id,
            user_name: username,
            contact_no: contactNumber,
            gender: gender,
            country: country,
            state: state,
            city: city,
            dob: dob,
            total_adult: alreadyAdults,
            total_child: alreadyChildren,
            room_title: roomId.room_title,
            price: roomId.price,
            total_booked_rooms: totalRooms,
            check_in_date: checkindate,
            check_out_date: checkoutdate,
            payment_type: paymentType,
        };

        localStorage.setItem('bookingDetail', JSON.stringify(bookingData));

        window.location.href = data?.data?.url
    }


    return (
        <div className="book-hotel-modal-overlay">
            <ToastContainer />
            <div className="book-hotel-modal-content">
                <h2>Book Your Hotel</h2>
                <div >
                    <div className="row mb-4">
                        <div className="col-4">
                            <label>Fullname</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label>Contact Number</label>
                            <input
                                type="text"
                                value={contactNumber}
                                onChange={(e) => setContactNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label>Gender</label>
                            <select
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-4">
                            <label>Country</label>
                            <select
                                value={country}
                                onChange={handleCountryChange}
                                required
                            >
                                <option value="" disabled>Select</option>
                                {countries && countries.map((e) => (
                                    <option value={e.name}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <label>State</label>
                            <select
                                value={state}
                                onChange={handleStateChange}
                                required
                            >
                                <option value="" disabled>Select</option>
                                {states && states.map((e) => (
                                    <option value={e.name}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <label>City</label>
                            <select
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select</option>
                                {cities && cities.map((e) => (
                                    <option value={e.name}>{e.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-4">
                            <label>DOB</label>
                            <input
                                type="date"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                required
                            />
                        </div>
                        <div className="col-4">
                            <label>Total Adult</label>
                            <input
                                type="number"
                                value={alreadyAdults}
                                // onChange={(e) => setDob(e.target.value)}
                                // required
                                disabled
                            />

                        </div>
                        <div className="col-4">
                            <label>Total Child</label>
                            <input
                                type="number"
                                value={alreadyChildren}
                                // onChange={(e) => setDob(e.target.value)}
                                // required
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-4">
                            <label>Room Title</label>
                            <input
                                type="text"
                                value={roomId.room_title}
                                disabled
                            />
                        </div>
                        <div className="col-4">
                            <label>Price</label>
                            <input
                                type="text"
                                value={roomId.price + " per night"}
                                // onChange={(e) => setRoomPrice(e.target.value)}
                                disabled
                            />
                        </div>
                        <div className="col-4">
                            <label>Total Rooms</label>
                            <input
                                type="number"
                                value={totalRooms}
                                // onChange={(e) => setRoomPrice(e.target.value)}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-4">
                            <label>Check-in Date</label>
                            <input
                                type="date"
                                value={checkindate}
                                diabled
                            />
                        </div>
                        <div className="col-4">
                            <label>Check-out Date</label>
                            <input
                                type="date"
                                value={checkoutdate}
                                disabled
                            />
                        </div>
                        <div className=" col-4">
                            <label>Payment Type</label>
                            <select
                                value={paymentType}
                                onChange={(e) => setPaymentType(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select Payment Type</option>
                                <option value="credit-card">Credit Card</option>
                                <option value="debit-card">Debit Card</option>
                                <option value="paypal">PayPal</option>
                                <option value="cash">Cash</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end gap-3">
                        <div className="">
                            <button className="booking-modal-buttons-1" onClick={onClose}>Close</button>
                        </div>
                        <div className="">
                            <button type="button" onClick={handlePrice} className='booking-modal-buttons-2'>Book Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingModal;
