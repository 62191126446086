import {
  faBus,
  faCar,
  faChevronLeft,
  faChevronRight,
  faPlane,
  faTrain,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { FormCheck, Row } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import "./css/index1.css"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BASE_URL } from "../BASE_URL"
import DatePicker, {
  DateObject,
  getAllDatesInRange,
  Calendar,
} from "react-multi-date-picker"
import { useNavigate } from "react-router-dom"
import { DateRangePicker } from "react-date-range"
import Countries from "../CountryStateCity.json"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

function Practic({ btn_name, setShow }) {
  const indiaCountry = Countries.find((country) => country.name === "India")

  const [states, setStates] = useState("")
  const [citie, setCities] = useState("")

  const [details, setDetails] = useState({
    departure: "",
    destination: "",
    total_adult: 2,
    total_child: 0,
    Infant: 0,
    personal_care: "no",
    additional_requirement: "",
    full_name: "",
    email_address: "",
    mobile_no: "",
    city: "",
    state: "",
    budget_per_person: 5000,
  })

  const userToken = localStorage.getItem("userToken")

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  const handleStateChange = (event) => {
    const selectedStateName = event.target.value;
    setStates(selectedStateName);
    const selectedState = indiaCountry.states.find(
      (state) => state.name === selectedStateName
    );
    setCities(selectedState);

    // Update the state directly in the details object
    setDetails({ ...details, state: selectedStateName, city: "" });
  };

  useEffect(() => {
    const selectedState = indiaCountry.states.find(
      (state) => state.name === states,
    )
    setCities(selectedState)
  }, [states])

  const [selectedRange, setSelectedRange] = useState([])
  const [startDateValidation, setStartDateValidation] = useState([])
  const [endDateValidation, setEndDateValidation] = useState([])
  const [currentDateValidation, setCurrentDateValidation] = useState([])

  const isDateBeforeCurrent = (date) => {
    const currentDate = new Date()
    return date < currentDate
  }

  const handleDateChange = (value) => {
    if (Array.isArray(value) && value.length >= 2) {
      const [startDateString, endDateString] = value
      const startDate = new Date(startDateString)
      const endDate = new Date(endDateString)

      setStartDateValidation(startDate)
      setEndDateValidation(endDate)
      setCurrentDateValidation(new Date())

      setSelectedRange([startDate, endDate])
    }
  }

  const customerRequirmentToken = localStorage.getItem("customerRequirment")
  const parsedData = JSON.parse(customerRequirmentToken)

  // profile details

  const [profile, setProfile] = useState("")

  const Profile = async () => {
    const token = localStorage.getItem("userToken")
    const res = await fetch(`${BASE_URL}user/userprofile`, {
      method: "GET",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setProfile(data?.data?.[0])
    setStates(data?.data?.[0]?.user_details?.[0]?.state)

    setDetails((prevDetails) => ({
      ...prevDetails,
      mobile_no: data?.data?.[0]?.phone || "",
      email_address: data?.data?.[0]?.user_details?.[0]?.email_address || "",
      full_name: data?.data?.[0]?.user_details?.[0]?.name || "",
      city: data?.data?.[0]?.user_details?.[0]?.city || "",
      state: data?.data?.[0]?.user_details?.[0]?.state || "",
    }))
  }

  useEffect(() => {
    Profile()
  }, [])

  const [number, setNumber] = useState(3)

  const increment = () => {
    if (number < 30) {
      setNumber(number + 1)
    }
  }

  const decrement = () => {
    number === 0 ? setNumber(0) : setNumber(number - 1)
  }

  const [error, setError] = useState("")

  const [date, setDate] = useState(new Date())
  const [show1, setShow1] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(false)
  const [show5, setShow5] = useState(false)
  const [show6, setShow6] = useState(false)
  const [show7, setShow7] = useState(false)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => {
    userToken ? setShow1(true) : setShow(true)
  }

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  const handleClose4 = () => setShow4(false)
  const handleShow4 = () => setShow4(true)

  const handleClose5 = () => setShow5(false)
  const handleShow5 = () => setShow5(true)

  const handleClose6 = () => setShow6(false)
  const handleShow6 = () => setShow6(true)

  const click1 = () => {
    if (
      !details?.departure &&
      !details.destination &&
      selectedValues.length === 0
    ) {
      toast.error("Please Fill All Field!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (details?.departure.trim() === "") {
      toast.error("Please Enter Departure", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (details.destination.trim() === "") {
      toast.error("Please Enter Destination", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (selectedValues.length === 0) {
      toast.error("Please select at least one checkbox!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    handleClose1(false)
    handleShow2(true)
  }

  // page 2
  const click2 = () => {
    if (
      !details.total_adult &&
      selectedValuesTravel.length === 0 &&
      !details.personal_care
    ) {
      // setError("Please fill all field");
      toast.error("Please fill all field!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      // alert("Please fill all fields")
      // handleClose1(true);
      return
    }

    if (selectedValuesTravel.length === 0) {
      // setError("Please fill all field");
      toast.error("Please Select Travel By!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      // alert("Please fill all fields")
      // handleClose1(true);
      return
    }

    handleClose2(false)
    handleShow3(true)
  }

  const back1 = () => {
    handleClose2(false)
    handleShow1(true)
  }

  // page 3

  const click3 = () => {
    if (selectedRange.length === 0 || number === 0) {
      toast.error("Please fill all field!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (
      startDateValidation < currentDateValidation ||
      endDateValidation < currentDateValidation
    ) {
      toast.error(
        "Please select a start and end date after the current date!",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        },
      )
      return
    }

    handleClose3(false)
    handleShow4(true)
  }

  const back2 = () => {
    handleClose3(false)
    handleShow2(true)
  }

  // page 4

  const validateRequiredFields = () => {
    // Replace these conditions with your actual required fields and their values
    const requiredFields = [
      selectedValuesHotel,
      notRequired !== "" ? notRequired : selectedValuesMeal,
      selectedValueMealType,
      // Add more required fields here
    ]

    const hasEmptyField = requiredFields.some((field) => field.length === 0)
    return !hasEmptyField
  }

  const click4 = () => {
    // Add your validation logic here
    const requiredFieldsFilled = validateRequiredFields() // Replace with your validation logic

    if (requiredFieldsFilled) {
      handleClose4(false)
      handleShow5(true)
    } else {
      toast.error("Please fill all field!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
    }
  }

  const back3 = () => {
    handleClose4(false)
    handleShow3(true)
  }

  // 5

  const click5 = () => {
    if (details.additional_requirement.length === 0) {
      // setError("Please select a date range");
      // setError("Please fill all field");
      toast.error("Please add extra requirments!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    if (details.additional_requirement.trim() === "") {
      toast.error("Please add extra requirments!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      })
      return
    }

    handleClose5(false)
    handleShow6(true)
  }

  const back4 = () => {
    handleClose5(false)
    handleShow4(true)
  }

  // 6

  const click6 = () => {
    handleClose5(false)
    // handleShow5(true);
  }

  const back5 = () => {
    handleClose6(false)
    handleShow5(true)
  }

  const CssTextField = styled(TextField)({
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
  })

  //api

  const [categoty, setCategoty] = useState([])

  const getCategoty = async () => {
    const res = await fetch(`${BASE_URL}destinationcategory`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const data = await res.json()
    setCategoty(data.data)
  }

  const defaultCheckedCategory = "Pilgrimage"

  const [selectedValues, setSelectedValues] = useState([
    "622ef03f0eec039c98528636",
  ])

  const handleCheckboxChange = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((v) => v !== value))
    } else {
      setSelectedValues([...selectedValues, value])
    }
  }

  const [selectedValuesTravel, setSelectedValuesTravel] = useState(["Train"])

  const handleCheckboxChangeTravel = (value) => {
    if (selectedValuesTravel.includes(value)) {
      setSelectedValuesTravel(selectedValuesTravel.filter((v) => v !== value))
    } else {
      setSelectedValuesTravel([...selectedValuesTravel, value])
    }
  }

  const [selectedValuesHotel, setSelectedValuesHotel] = useState(["Any"])

  const handleCheckboxChangeHotel = (value) => {
    if (value === "Any" || value === "No" || value === "Budget Property") {
      setSelectedValuesHotel([value])
    } else {
      setSelectedValuesHotel((prevSelected) => {
        if (
          prevSelected.includes("Any") ||
          prevSelected.includes("No") ||
          prevSelected.includes("Budget Property")
        ) {
          prevSelected = prevSelected.filter(
            (v) => v !== "Any" && v !== "No" && v !== "Budget Property",
          )
        }

        if (prevSelected.includes(value)) {
          return prevSelected.filter((v) => v !== value)
        } else {
          return [...prevSelected, value]
        }
      })
    }
  }

  const [notRequired, setNotRequired] = useState("")

  const [selectedValuesMeal, setSelectedValuesMeal] = useState(["Breakfast"])
  const [selectedValueMealType, setSelectedValueMealType] = useState("Any")

  const handleCheckboxChangeMeal = (value) => {
    setNotRequired("");
    setSelectedValueMealType("Any");

    // Check if "Not Required" is in the array and remove it if it is
    const updatedSelectedValues = selectedValuesMeal.includes("Not Required")
      ? selectedValuesMeal.filter((v) => v !== "Not Required")
      : [...selectedValuesMeal];

    if (updatedSelectedValues.includes(value)) {
      // If the value is already selected, remove it
      setSelectedValuesMeal(updatedSelectedValues.filter((v) => v !== value));
    } else {
      // Otherwise, add the value
      setSelectedValuesMeal([...updatedSelectedValues, value]);
    }
  };


  const handleCheckboxChangeMealType = (value) => {
    if (selectedValuesMeal.includes("Not Required")) {
      setSelectedValueMealType("any");
    } else {
      setSelectedValueMealType(value);
    }
  };


  const handleNotRequiredChange = (e) => {
    setSelectedValuesMeal(["Not Required"])
    handleCheckboxChangeMealType("Not Required")
    setNotRequired("Not Required")
  }

  const [errors, setErrors] = useState("")

  const txt = (e) => {
    const { name, value } = e.target
    setDetails({ ...details, [name]: value })
    if (name === "budget_per_person") {
      const budgetValue = parseInt(value, 10)
      if (budgetValue <= 5000) {
        setErrors("Please Enter Amount Above 5000")
      }
    } else {
      setErrors("")
    }
  }

  const customerRequirment = {
    details,
    selectedValues,
    selectedValuesTravel,
    selectedValuesHotel,
    selectedValuesMeal,
  }

  const navigate = useNavigate()

  const CustomPackage = async () => {
    const token = localStorage.getItem("userToken")

    if (!token) {
      if (
        !details.full_name &&
        !details.email_address &&
        !details.mobile_no &&
        !details.city &&
        !details.budget_per_person
      ) {
        toast.error("Please Fill All Field!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return // Stop the function execution if validation fails
      }

      if (!details.full_name) {
        toast.error("Please Enter Full Name!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (details.full_name.trim() === "") {
        toast.error("Please Enter Full Name!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!details.email_address) {
        toast.error("Please Enter Email Address!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (!emailRegex.test(details.email_address)) {
        toast.error("Please enter a valid email address!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!details.mobile_no) {
        toast.error("Please Enter Mobile Number!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (details.mobile_no.trim() === "") {
        toast.error("Please Enter Full Name!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (details.mobile_no.length !== 10) {
        toast.error("Please Enter Valid Mobile Number!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!states) {
        toast.error("Please Select State!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!details.city) {
        toast.error("Please Select City!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!details.budget_per_person) {
        toast.error("Please Enter Budget Per Person!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (details.budget_per_person.trim() === "") {
        toast.error("Please Enter Budget Per Person!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (details.budget_per_person < 5000) {
        toast.error("Please Enter Amount Above 5000!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }
      alert("Please Login First")
      handleClose6(false)
      localStorage.setItem(
        "customerRequirment",
        JSON.stringify(customerRequirment),
      )
    } else {
      const {
        departure,
        destination,
        total_adult,
        total_child,
        Infant,
        personal_care,
        additional_requirement,
        full_name,
        email_address,
        mobile_no,
        city,
        state,
        budget_per_person,
      } = details

      if (
        !details.full_name &&
        !details.email_address &&
        !details.mobile_no &&
        !details.city &&
        !details.budget_per_person
      ) {
        toast.error("Please Fill All Field!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return // Stop the function execution if validation fails
      }

      if (!details.full_name) {
        toast.error("Please Enter Full Name!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (details.full_name.trim() === "") {
        toast.error("Please Enter Full Name!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!details.email_address) {
        toast.error("Please Enter Email Address!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (!emailRegex.test(details.email_address)) {
        toast.error("Please enter a valid email address!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!details.mobile_no) {
        toast.error("Please Enter Mobile Number!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (details.mobile_no.length < 10) {
        toast.error("Please Enter Valid Mobile Number!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!states) {
        toast.error("Please Select State!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!details.city) {
        toast.error("Please Select City!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (!details.budget_per_person) {
        toast.error("Please Enter Budget Per Person!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      if (details.budget_per_person < 5000) {
        toast.error("Please Enter Amount Above 5000!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        return
      }

      const res = await fetch(`${BASE_URL}customrequirements`, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          departure,
          destination,
          category: selectedValues,
          total_adult,
          total_child,
          Infant,
          travel_by: selectedValuesTravel,
          personal_care,
          start_date: selectedRange[0].toLocaleDateString(),
          end_date: selectedRange[1].toLocaleDateString(),
          hotel_type: selectedValuesHotel,
          meal_require: selectedValuesMeal,
          meal_type: selectedValueMealType,
          additional_requirement,
          full_name,
          email_address,
          mobile_no: Number(mobile_no),
          city,
          state,
          budget_per_person,
          total_travel_days: number,
        }),
      })
      const data = await res.json()

      if (data.code == 200) {

        toast.success('Custom Requirement Submitted', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
        setDetails({
          departure: "",
          destination: "",
          total_adult: 2,
          total_child: 0,
          Infant: 0,
          personal_care: "no",
          additional_requirement: "",
          budget_per_person: 5000,
        })

        setSelectedValues([])
        setSelectedValuesTravel([])
        setSelectedRange([])
        setSelectedValuesHotel([])
        setSelectedValuesMeal([])
        setSelectedValueMealType([])
        setSelectedRange([])

        handleClose6(false)
        navigate("/custom_requirement")
        toast.success("Requirment Saved Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        })
        localStorage.removeItem("customerRequirment")
      }
    }
  }

  useEffect(() => {
    getCategoty()
    setTimeout(() => {
      localStorage.removeItem("customerRequirment")
    }, 24 * 60 * 60 * 1000)
  }, [])

  return (
    <>
      <a variant='primary' onClick={handleShow1} className='book_now_btn font600 text15'>
        {btn_name}
      </a>
      {/* 1 */}
      <Modal
        show={show1}
        backdrop='static'
        keyboard={false}
        size='lg'
        className='m-o p-2 '
      >
        <Modal.Body className='custom_package_image_fix p-0'>
          <Row>
            <div
              className='col-md-4 px-0 d-flex justify-content-center align-items-center'
              style={{ background: "#D6D6D6" }}
            >
              <img src='./Tourism.png' alt='' style={{ width: "100%" }} />
            </div>
            <div className='col-lg-8 col-12 px-0'>
              <div className='float-end pe-4 pt-2 p-2' onClick={handleClose1}>
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ fontSize: "25px", cursor: "pointer" }}
                />
              </div>
              <div className='text-center pt-5'>
                <img
                  src='/modal1.png'
                  className='img-fluid'
                  style={{ marginRight: "10px" }}
                  alt='modal'
                />
                <p
                  style={{
                    fontSize: mobile ? "16px" : "20px",
                    fontWeight: "600",
                    marginRight: "8px",
                    color: "#09646D",
                  }}
                >
                  Where would you like to go?
                </p>
              </div>
              <div style={{ marginLeft: "15px", marginRight: "30px" }}>
                {/* departure  */}

                <div className='py-1 mb-2'>
                  {selectedValues.length === 0 ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {error}
                    </span>
                  ) : (
                    ""
                  )}
                  <p
                    className='cmnp mb-1 text16 font700'
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    Departure
                  </p>
                  {customerRequirmentToken &&
                    customerRequirmentToken.length > 0 ? (
                    <input
                      type='text'
                      placeholder='Departure'
                      className='w-100 p-2'
                      onChange={txt}
                      name='departure'
                      pattern='^[a-zA-Z][\sa-zA-Z]*'
                      value={parsedData.details.departure}
                      style={{
                        border: "1px solid #B8B8B8",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <input
                      type='text'
                      placeholder='Departure'
                      className='w-100 p-2'
                      onChange={txt}
                      value={details.departure}
                      pattern='^[a-zA-Z][\sa-zA-Z]*'
                      name='departure'
                      style={{
                        border: "1px solid #B8B8B8",
                        borderRadius: "10px",
                      }}
                    />
                  )}
                </div>

                {/* destination   */}

                <div className='py-1'>
                  <p
                    className='text16 font700 cmnp mb-1'

                  >
                    Destination
                  </p>
                  {customerRequirmentToken &&
                    customerRequirmentToken.length > 0 ? (
                    <input
                      type='text'
                      placeholder='Destination'
                      className='w-100 p-2'
                      onChange={txt}
                      name='departure'
                      value={parsedData.details.destination}
                      style={{
                        border: "1px solid #B8B8B8",
                        borderRadius: "10px",
                      }}
                    />
                  ) : (
                    <input
                      type='text'
                      placeholder='Destination'
                      onChange={txt}
                      className='w-100 p-2'
                      value={details.destination}
                      name='destination'
                      style={{
                        borderRadius: "10px",
                        border: "1px solid #B8B8B8",
                      }}
                    />
                  )}
                </div>
              </div>

              {/* category */}
              <h5 className='text16 font700 mt-2 mb-0 ms-3'>
                Category
              </h5>
              <Row className='py-1'>
                {categoty.map((ele) => {
                  return (
                    <>
                      <div className='col-6' key={ele._id}>
                        <div className='py-1 mx-lg-5 mx-sm-3 mx-0 ms-2'>
                          <div>
                            <div className='py-1 d-flex'>
                              {customerRequirmentToken &&
                                customerRequirmentToken.length > 0 ? (
                                <FormCheck
                                  className='p-0 m-0'
                                  name='Pilgrimage'
                                  value={ele._id}
                                  checked={parsedData.selectedValues.includes(
                                    `${ele._id}`,
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(`${ele._id}`)
                                  }
                                />
                              ) : (
                                <FormCheck
                                  className='p-0 m-0'
                                  name='Pilgrimage'
                                  value={ele._id}
                                  checked={selectedValues.includes(
                                    `${ele._id}`,
                                  )}
                                  onChange={() =>
                                    handleCheckboxChange(`${ele._id}`)
                                  }
                                />
                              )}
                              <span className='ms-1'>{ele.category_name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </Row>

              <div className='d-flex justify-content-center'>
                <div className='py-3 w-75'>
                  <p
                    className='load_btn text-center'
                    style={{ cursor: "pointer" }}
                    onClick={click1}
                  >
                    Next
                  </p>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
        <ToastContainer />
      </Modal>

      {/* 2 */}
      <Modal
        show={show2}
        backdrop='static'
        keyboard={false}
        className='m-o p-2'
        size='lg'
      >
        <Modal.Body className='custom_package_image_fix p-0'>
          <Row className='h-100'>
            <div className='col-md-4 side modal_2_background'>
              <div className='pt-2 ps-3'>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{
                    background: "#09646D",
                    color: "#fff",
                    borderRadius: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={back1}
                />
              </div>
            </div>
            <div className='col-lg-8 px-4 mx-md-0 mx-2 '>
              <div className='d-flex align-items-center h-100 w-100'>
                <div className='w-100'>
                  <div className='cross-button-position' onClick={handleClose2}>
                    <FontAwesomeIcon icon={faXmark} className='cross' />
                  </div>
                  <div className='text-center pt-5'>
                    <h5
                      className='py-1 pb-3'
                      style={{
                        fontWeight: "600",
                        marginRight: "10px",
                        fontSize: mobile ? "16px" : "20px",
                      }}
                    >
                      Give Us More Info
                    </h5>
                    {!details.total_adult ||
                      selectedValuesTravel.length === 0 ||
                      !details.personal_care ? (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {error}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className='py-1'>
                    <p className='cmnp lato text16 font700'>
                      Age Group
                    </p>
                  </div>
                  <Row>
                    <div className='col-md-4 col-6 p-1 m-0 text-center'>
                      <div className='py-1'>
                        <p className='cmnp' style={{ fontSize: "10px" }}>
                          Adults{" "}
                          <span
                            className='breack_span'
                            style={{ color: "#B4B4B4" }}
                          >
                            (12+ Years)
                          </span>
                        </p>
                        <div class='anyone'>
                          <FontAwesomeIcon icon={faUser} />
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <select
                              name='total_adult'
                              id='cars'
                              value={parsedData.details.total_adult}
                              onChange={txt}
                            >
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                            </select>
                          ) : (
                            <select
                              name='total_adult'
                              id='cars'
                              onChange={txt}
                              value={details.total_adult}
                            >
                              <option value='1'>1</option>
                              <option value='2' selected>
                                2
                              </option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 col-6 p-1 m-0 text-center'>
                      <div className='py-1'>
                        <p className='cmnp' style={{ fontSize: "10px" }}>
                          Children{" "}
                          <span
                            className='breack_span'
                            style={{ color: "#B4B4B4" }}
                          >
                            (2 to 12 Years)
                          </span>
                        </p>
                        <div class='anyone'>
                          <FontAwesomeIcon icon={faUser} />
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <select
                              name='total_child'
                              id='cars'
                              value={parsedData.details.total_child}
                              onChange={txt}
                            >
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                            </select>
                          ) : (
                            <select
                              name='total_child'
                              id='cars'
                              onChange={txt}
                              value={details.total_child}
                            >
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                              <option value='5'>5</option>
                              <option value='6'>6</option>
                              <option value='7'>7</option>
                              <option value='8'>8</option>
                              <option value='9'>9</option>
                              <option value='10'>10</option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-md-4 col-6 p-1 m-0 text-center'>
                      <div className='py-1'>
                        <p className='cmnp' style={{ fontSize: "10px" }}>
                          Infant{" "}
                          <span
                            className='breack_span'
                            style={{ color: "#B4B4B4" }}
                          >
                            (0 to 2 Years)
                          </span>
                        </p>
                        <div class='anyone'>
                          <FontAwesomeIcon icon={faUser} />
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <select
                              name='Infant'
                              id='cars'
                              value={parsedData.details.Infant}
                              onChange={txt}
                            >
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                            </select>
                          ) : (
                            <select
                              name='Infant'
                              id='cars'
                              onChange={txt}
                              value={details.Infant}
                            >
                              <option value='0'>0</option>
                              <option value='1'>1</option>
                              <option value='2'>2</option>
                              <option value='3'>3</option>
                              <option value='4'>4</option>
                            </select>
                          )}
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className='py-1 mt-2'>
                    <p className='cmnp lato text16 font700'>
                      Travel by
                    </p>
                  </div>
                  <Row>
                    <div class='col-md-6 col-12 p-1 m-0 px-3'>
                      <div>
                        <div class='check-box check-box-1  m-0 p-0'>
                          <label for='whyWas' class='w-75 text-center'>
                            {customerRequirmentToken &&
                              customerRequirmentToken.length > 0 ? (
                              <input
                                type='checkbox'
                                id='whyWas'
                                name='Train'
                                value='Train'
                                checked={parsedData.selectedValuesTravel.includes(
                                  "Train",
                                )}
                                onChange={() =>
                                  handleCheckboxChangeTravel("Train")
                                }
                              />
                            ) : (
                              <input
                                type='checkbox'
                                id='whyWas'
                                name='Train'
                                value='Train'
                                checked={selectedValuesTravel.includes("Train")}
                                onChange={() =>
                                  handleCheckboxChangeTravel("Train")
                                }
                              />
                            )}
                            <span class='klass1 w-100'>
                              <FontAwesomeIcon icon={faTrain} /> Train
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class='col-md-6 col-12 p-1 m-0 px-3'>
                      <div class='check-box check-box-2 m-o p-0'>
                        <label for='whySo' class='w-75 text-center'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <input
                              type='checkbox'
                              id='whySo'
                              name='Bus'
                              value='Bus'
                              checked={parsedData.selectedValuesTravel.includes(
                                "Bus",
                              )}
                              onChange={() => handleCheckboxChangeTravel("Bus")}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              id='whySo'
                              name='Bus'
                              value='Bus'
                              checked={selectedValuesTravel.includes("Bus")}
                              onChange={() => handleCheckboxChangeTravel("Bus")}
                            />
                          )}
                          <span class='klass2 w-100'>
                            <FontAwesomeIcon icon={faBus} /> Bus
                          </span>
                        </label>
                      </div>
                    </div>
                    <div class='col-md-6 col-12 p-1 m-0 px-3'>
                      <div class='check-box check-box-0 p-0 m-0'>
                        <label for='whyNeed' class='w-75 text-center'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <input
                              type='checkbox'
                              id='whyNeed'
                              name='Flight'
                              value='Flight'
                              checked={parsedData.selectedValuesTravel.includes(
                                "Flight",
                              )}
                              onChange={() =>
                                handleCheckboxChangeTravel("Flight")
                              }
                            />
                          ) : (
                            <input
                              type='checkbox'
                              id='whyNeed'
                              name='Flight'
                              value='Flight'
                              checked={selectedValuesTravel.includes("Flight")}
                              onChange={() =>
                                handleCheckboxChangeTravel("Flight")
                              }
                            />
                          )}
                          <span class='klass w-100'>
                            <FontAwesomeIcon icon={faPlane} /> Flight
                          </span>
                        </label>
                      </div>
                    </div>

                    <div class='col-md-6 col-12 p-1 m-0 px-3'>
                      <div class='check-box check-box-3 m-0 p-0'>
                        <label for='whyMe' class='w-75 text-center'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <input
                              type='checkbox'
                              id='whyMe'
                              name='Car'
                              value='Car'
                              checked={parsedData.selectedValuesTravel.includes(
                                "Car",
                              )}
                              onChange={() => handleCheckboxChangeTravel("Car")}
                            />
                          ) : (
                            <input
                              type='checkbox'
                              id='whyMe'
                              name='Car'
                              value='Car'
                              checked={selectedValuesTravel.includes("Car")}
                              onChange={() => handleCheckboxChangeTravel("Car")}
                            />
                          )}
                          <span class='klass3 w-100'>
                            <FontAwesomeIcon icon={faCar} /> Cab/Car
                          </span>
                        </label>
                      </div>
                    </div>
                  </Row>

                  <div className='text-start mt-2'>
                    <p className='cmnp lato text16 font700'>
                      Need any personal care?{" "}
                    </p>
                    <div className='p-2 pb-0'>
                      {customerRequirmentToken &&
                        customerRequirmentToken.length > 0 ? (
                        <input
                          type='radio'
                          name='personal_care'
                          checked={parsedData.details.personal_care.includes(
                            "yes",
                          )}
                          value='yes'
                          onChange={txt}
                          id='YN'
                        />
                      ) : (
                        <input
                          type='radio'
                          name='personal_care'
                          value='yes'
                          onChange={txt}
                          checked={details.personal_care.includes("yes")}
                          id='YN'
                        />
                      )}

                      <label for='radio' className='ps-1'>
                        Yes
                      </label>
                      <br />
                      {customerRequirmentToken &&
                        customerRequirmentToken.length > 0 ? (
                        <input
                          type='radio'
                          name='personal_care'
                          checked={parsedData.details.personal_care.includes(
                            "no",
                          )}
                          value='no'
                          onChange={txt}
                          id='YN'
                        />
                      ) : (
                        <input
                          type='radio'
                          name='personal_care'
                          value='no'
                          checked={details.personal_care.includes("no")}
                          onChange={txt}
                          id='YN'
                        />
                      )}

                      <label for='radio' className='ps-1'>
                        No
                      </label>
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <div className='py-3 w-75'>
                      <p
                        className='load_btn text-center'
                        style={{ cursor: "pointer" }}
                        onClick={click2}
                      >
                        Next
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* 3 */}
      <Modal
        show={show3}
        backdrop='static'
        keyboard={false}
        className='m-o p-2'
        size='lg'
      >
        <Modal.Body className='custom_package_image_fix p-0'>
          <Row className='h-100'>
            <div className='col-md-4 side modal_3_background'>
              <div className='pt-2 ps-3'>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{
                    background: "#09646D",
                    color: "#fff",
                    borderRadius: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={back2}
                />
              </div>
            </div>
            <div className='col-lg-8 px-4'>
              <div className='d-flex align-items-center h-100 w-100'>
                <div className='w-100'>
                  <div className='cross-button-position' onClick={handleClose3}>
                    <FontAwesomeIcon icon={faXmark} className='cross' />
                  </div>
                  <div
                    style={{
                      fontSize: "26px",
                      fontWeight: "600",
                      textAlign: "center",
                      margin: "20px 0px",
                    }}
                  >
                    Departure Between & Travel Days
                  </div>
                  {selectedRange.length === 0 ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {error}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className='containerFor d-flex flex-column justify-content-center align-items-center calender_parent'>
                    <Calendar
                      value={selectedRange}
                      onChange={handleDateChange}
                      range
                      rangeHovery
                      style={{
                        paddingBottom: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        width: "100%",
                      }}
                      minDate={new Date()}
                    />

                    <div className='text-center calender_btn'>
                      <div
                        class='btn-group d-flex flex-wrap align-items-center'
                        role='group'
                        aria-label='Basic example'
                      >
                        <h6 className='mb-0 me-2'>Travel Days</h6>
                        <button
                          type='button'
                          class='btn first_bttn'
                          onClick={decrement}
                          style={{
                            marginRight: "2px",
                            lineHeight: "0px",
                          }}
                        >
                          -
                        </button>
                        <button
                          type='button'
                          class='btn second_bttn'
                          style={{ lineHeight: "0px" }}
                        >
                          {number}
                        </button>
                        <button
                          type='button'
                          class='btn third_bttn'
                          onClick={increment}
                          style={{ lineHeight: "0px", marginLeft: "1.2px" }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-center mt-3'>
                    <div className='py-1  w-75'>
                      <p
                        className='load_btn text-center'
                        style={{ cursor: "pointer" }}
                        onClick={click3}
                      >
                        Next
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* 4 */}
      <Modal
        show={show4}
        backdrop='static'
        keyboard={false}
        className='m-o p-2'
        size='lg'
      >
        <Modal.Body className='custom_package_image_fix p-0'>
          <Row className='h-100'>
            <div className='col-md-4 side modal_4_background'>
              <div className='pt-2 ps-3'>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{
                    background: "#09646D",
                    color: "#fff",
                    borderRadius: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={back3}
                />
              </div>
            </div>
            <div className='col-lg-8 container px-3 px-lg-5'>
              <div className='d-flex align-items-center h-100 w-100'>
                <div className='w-100'>
                  <div className='cross-button-position' onClick={handleClose4}>
                    <FontAwesomeIcon icon={faXmark} className='cross' />
                  </div>
                  <div
                    style={{
                      fontSize: mobile ? "16px" : "20px",
                      fontWeight: "600",
                      textAlign: "center",
                      margin: "20px 0px",
                    }}
                  >
                    Hotel & Meals
                  </div>
                  <div>
                    <div
                      className="lato text18 font700 mb-1"
                      style={{
                        // fontSize: "22px",
                        // fontWeight: "600",
                        // margin: "50px 0px 30px",
                      }}
                    >
                      Hotel Type
                    </div>
                  </div>
                  <Row>
                    <div className='col-6'>
                      <div>
                        <div className='py-1 d-flex'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <FormCheck
                              className='p-0 m-0'
                              name='2 Star'
                              value='2 Star'
                              checked={parsedData.selectedValuesHotel.includes(
                                "2 Star",
                              )}
                              onChange={() =>
                                handleCheckboxChangeHotel("2 Star")
                              }
                            />
                          ) : (
                            <FormCheck
                              className='p-0 m-0'
                              name='2 Star'
                              value='2 Star'
                              checked={selectedValuesHotel.includes("2 Star")}
                              onChange={() =>
                                handleCheckboxChangeHotel("2 Star")
                              }
                            />
                          )}
                          <span className='ms-1'>2 Star</span>
                        </div>
                        <ToastContainer />
                        <div className='py-1 d-flex'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <FormCheck
                              className='p-0 m-0'
                              name='4 Star'
                              value='4 Star'
                              checked={parsedData.selectedValuesHotel.includes(
                                "4 Star",
                              )}
                              onChange={() =>
                                handleCheckboxChangeHotel("4 Star")
                              }
                            />
                          ) : (
                            <FormCheck
                              className='p-0 m-0'
                              name='4 Star'
                              value='4 Star'
                              checked={selectedValuesHotel.includes("4 Star")}
                              onChange={() =>
                                handleCheckboxChangeHotel("4 Star")
                              }
                            />
                          )}

                          <span className='ms-1'>4 Star</span>
                        </div>
                        <div className='py-1 d-flex'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <FormCheck
                              className='p-0 m-0'
                              name='Any'
                              value='Any'
                              checked={parsedData.selectedValuesHotel.includes(
                                "Any",
                              )}
                              onChange={() => handleCheckboxChangeHotel("Any")}
                            />
                          ) : (
                            <FormCheck
                              className='p-0 m-0'
                              name='Any'
                              value='Any'
                              checked={selectedValuesHotel.includes("Any")}
                              onChange={() => handleCheckboxChangeHotel("Any")}
                            />
                          )}
                          <span className='ms-1'>Any</span>
                        </div>
                        <div className='py-1 d-flex'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <FormCheck
                              className='p-0 m-0'
                              name='Budget Property'
                              value='Budget Property'
                              checked={parsedData.selectedValuesHotel.includes(
                                "Budget Property",
                              )}
                              onChange={() =>
                                handleCheckboxChangeHotel("Budget Property")
                              }
                            />
                          ) : (
                            <FormCheck
                              className='p-0 m-0'
                              name='Budget Property'
                              value='Budget Property'
                              checked={selectedValuesHotel.includes(
                                "Budget Property",
                              )}
                              onChange={() =>
                                handleCheckboxChangeHotel("Budget Property")
                              }
                            />
                          )}
                          <span className='ms-1'>Budget Property</span>
                        </div>
                      </div>
                    </div>
                    <div className='col-6 ps-md-4'>
                      <div className='mx-lg-5 options'>
                        <div className='py-1 d-flex'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <FormCheck
                              className='p-0 m-0'
                              name='3 Star'
                              value='3 Star'
                              checked={parsedData.selectedValuesHotel.includes(
                                "3 Star",
                              )}
                              onChange={() =>
                                handleCheckboxChangeHotel("3 Star")
                              }
                            />
                          ) : (
                            <FormCheck
                              className='p-0 m-0'
                              name='3 Star'
                              value='3 Star'
                              checked={selectedValuesHotel.includes("3 Star")}
                              onChange={() =>
                                handleCheckboxChangeHotel("3 Star")
                              }
                            />
                          )}

                          <span className='ms-1'>3 Star</span>
                        </div>
                        <div className='py-1 d-flex'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <FormCheck
                              className='p-0 m-0'
                              name='5 Star'
                              value='5 Star'
                              checked={parsedData.selectedValuesHotel.includes(
                                "5 Star",
                              )}
                              onChange={() =>
                                handleCheckboxChangeHotel("5 Star")
                              }
                            />
                          ) : (
                            <FormCheck
                              className='p-0 m-0'
                              name='5 Star'
                              value='5 Star'
                              checked={selectedValuesHotel.includes("5 Star")}
                              onChange={() =>
                                handleCheckboxChangeHotel("5 Star")
                              }
                            />
                          )}

                          <span className='ms-1'>5 Star</span>
                        </div>
                        <div className='py-1 d-flex'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <FormCheck
                              className='p-0 m-0'
                              name='No'
                              value='No'
                              checked={parsedData.selectedValuesHotel.includes(
                                "No",
                              )}
                              onChange={() => handleCheckboxChangeHotel("No")}
                            />
                          ) : (
                            <FormCheck
                              className='p-0 m-0'
                              name='No'
                              value='No'
                              checked={selectedValuesHotel.includes("No")}
                              onChange={() => handleCheckboxChangeHotel("No")}
                            />
                          )}
                          <span className='ms-1'>No</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="mt-5">
                    <div className='row'>
                      <div className="col-6">
                        <div
                          className="lato text18 font700 mb-1"
                        >
                          Meals Required
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          className="lato text18 font700 mb-1 mx-lg-5"
                        >
                          Meals Type
                        </div>
                      </div>
                    </div>
                    <Row>
                      <div className='col-6'>
                        <div>
                          <div className='py-1 d-flex'>
                            <FormCheck
                              className='p-0 m-0'
                              name='Not Required'
                              value='Not Required'
                              checked={notRequired.includes("Not Required")}
                              onChange={() =>
                                handleNotRequiredChange("Not Required")
                              }
                            />
                            <label htmlFor='' className='ms-1'>
                              Not Required
                            </label>
                          </div>
                          <div className='py-1 d-flex'>
                            {customerRequirmentToken &&
                              customerRequirmentToken.length > 0 ? (
                              <FormCheck
                                className='p-0 m-0'
                                name='Breakfast'
                                value='Breakfast'
                                checked={parsedData.selectedValuesMeal.includes(
                                  "Breakfast",
                                )}
                                onChange={() =>
                                  handleCheckboxChangeMeal("Breakfast")
                                }
                              />
                            ) : (
                              <FormCheck
                                className='p-0 m-0'
                                name='Breakfast'
                                value='Breakfast'
                                checked={selectedValuesMeal.includes(
                                  "Breakfast",
                                )}
                                onChange={() =>
                                  handleCheckboxChangeMeal("Breakfast")
                                }
                              />
                            )}
                            <span className='ms-1'>Breakfast</span>
                          </div>
                          <div className='py-1 d-flex'>
                            {customerRequirmentToken &&
                              customerRequirmentToken.length > 0 ? (
                              <FormCheck
                                className='p-0 m-0'
                                name='Lunch'
                                value='Lunch'
                                checked={parsedData.selectedValuesMeal.includes(
                                  "Lunch",
                                )}
                                onChange={() =>
                                  handleCheckboxChangeMeal("Lunch")
                                }
                              />
                            ) : (
                              <FormCheck
                                className='p-0 m-0'
                                name='Lunch'
                                value='Lunch'
                                checked={selectedValuesMeal.includes("Lunch")}
                                onChange={() =>
                                  handleCheckboxChangeMeal("Lunch")
                                }
                              />
                            )}
                            <span className='ms-1'>Lunch</span>
                          </div>
                          <div className='py-1 d-flex'>
                            {customerRequirmentToken &&
                              customerRequirmentToken.length > 0 ? (
                              <FormCheck
                                className='p-0 m-0'
                                name='Dinner'
                                value='Dinner'
                                checked={parsedData.selectedValuesMeal.includes(
                                  "Dinner",
                                )}
                                onChange={() =>
                                  handleCheckboxChangeMeal("Dinner")
                                }
                              />
                            ) : (
                              <FormCheck
                                className='p-0 m-0'
                                name='Dinner'
                                value='Dinner'
                                checked={selectedValuesMeal.includes("Dinner")}
                                onChange={() =>
                                  handleCheckboxChangeMeal("Dinner")
                                }
                              />
                            )}
                            <span className='ms-1'>Dinner</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-6 ps-4'>
                        <div className='mx-lg-5 options'>
                          <div className='py-1 d-flex'>
                            {customerRequirmentToken &&
                              customerRequirmentToken.length > 0 ? (
                              <FormCheck
                                className='p-0 m-0'
                                name='meal_type'
                                value='Any'
                                checked={parsedData.details.meal_type === "Any"}
                                onChange={() =>
                                  handleCheckboxChangeMealType("Any")
                                }
                              />
                            ) : (
                              <FormCheck
                                className='p-0 m-0'
                                name='meal_type'
                                value='Any'
                                checked={
                                  (selectedValuesMeal.length > 0 ||
                                    notRequired.includes("Not Required")) &&
                                  selectedValueMealType === "Any"
                                }
                                disabled={selectedValuesMeal.length === 0}
                                onChange={() =>
                                  handleCheckboxChangeMealType("Any")
                                }
                              />
                            )}
                            <span className='ms-1'>Any</span>
                          </div>
                          <div className='py-1 d-flex'>
                            {customerRequirmentToken &&
                              customerRequirmentToken.length > 0 ? (
                              <FormCheck
                                className='p-0 m-0'
                                name='meal_type'
                                value='Veg'
                                checked={parsedData.details.meal_type === "Veg"}
                                onChange={() =>
                                  handleCheckboxChangeMealType("Veg")
                                }
                              />
                            ) : (
                              <FormCheck
                                className='p-0 m-0'
                                name='meal_type'
                                value='Veg'
                                checked={
                                  (selectedValuesMeal.length > 0 ||
                                    notRequired.includes("Not Required")) &&
                                  selectedValueMealType === "Veg"
                                }
                                disabled={selectedValuesMeal.length === 0}
                                onChange={() =>
                                  handleCheckboxChangeMealType("Veg")
                                }
                              />
                            )}
                            <span className='ms-1'>Veg</span>
                          </div>
                          <div className='py-1 d-flex'>
                            {customerRequirmentToken &&
                              customerRequirmentToken.length > 0 ? (
                              <FormCheck
                                className='p-0 m-0'
                                name='meal_type'
                                value='Non-Veg'
                                checked={
                                  parsedData.details.meal_type === "Non-Veg"
                                }
                                onChange={() =>
                                  handleCheckboxChangeMealType("Non-Veg")
                                }
                              />
                            ) : (
                              <FormCheck
                                className='p-0 m-0'
                                name='meal_type'
                                value='Non-Veg'
                                checked={
                                  (selectedValuesMeal.length > 0 ||
                                    notRequired.includes("Not Required")) &&
                                  selectedValueMealType === "Non-Veg"
                                }
                                disabled={selectedValuesMeal.length === 0}
                                onChange={() =>
                                  handleCheckboxChangeMealType("Non-Veg")
                                }
                              />
                            )}

                            <span className='ms-1'>Non-Veg</span>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </div>
                  <div className='d-flex justify-content-center mt-3'>
                    <div className='py-1  w-75'>
                      <p
                        className='load_btn text-center'
                        style={{ cursor: "pointer" }}
                        onClick={click4}
                      >
                        Next
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* 5 */}
      <Modal
        show={show5}
        backdrop='static'
        keyboard={false}
        className='m-o p-2'
        size='lg'
      >
        <Modal.Body className='custom_package_image_fix p-0'>
          <Row className='h-100'>
            <div className='col-md-4 side modal_5_background'>
              <div className='pt-2 ps-3'>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{
                    background: "#09646D",
                    color: "#fff",
                    borderRadius: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={back4}
                />
              </div>
            </div>
            <div className='col-lg-8 container px-3 px-lg-5'>
              <div className='d-flex align-items-center h-100 w-100'>
                <div className='w-100'>
                  <div className='cross-button-position' onClick={handleClose5}>
                    <FontAwesomeIcon icon={faXmark} className='cross' />
                  </div>
                  <div
                    style={{
                      fontSize: mobile ? "16px" : "20px",
                      fontWeight: "600",
                      textAlign: "center",
                      margin: "20px 0px",
                    }}
                  >
                    Need Any Extra Requirements?
                  </div>
                  <div>
                    {customerRequirmentToken &&
                      customerRequirmentToken.length > 0 ? (
                      <textarea
                        name='additional_requirement'
                        id=''
                        // cols="55"
                        rows='15'
                        placeholder='Type Your Requirements...'
                        className='textareaCSS'
                        onChange={txt}
                        value={parsedData.details.additional_requirement}
                      ></textarea>
                    ) : (
                      <textarea
                        name='additional_requirement'
                        id=''
                        rows='14'
                        placeholder='Type Your Requirements...'
                        className='textareaCSS'
                        onChange={txt}
                        value={details.additional_requirement}
                      ></textarea>
                    )}
                  </div>
                  {!details.additional_requirement ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {error}
                    </span>
                  ) : (
                    ""
                  )}
                  <div className='py-2 w-75 ps-4 mx-4 mt-3'>
                    <p
                      className='load_btn text-center'
                      style={{ cursor: "pointer" }}
                      onClick={click5}
                    >
                      Next <FontAwesomeIcon icon={faChevronRight} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* 6 */}
      <Modal
        show={show6}
        backdrop='static'
        keyboard={false}
        className='m-o p-2'
        size='lg'
      >
        <Modal.Body className='custom_package_image_fix p-0'>
          <Row className='h-100'>
            <div className='col-md-4 side modal_6_background'>
              <div className='pt-2 ps-3'>
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  style={{
                    background: "#09646D",
                    color: "#fff",
                    borderRadius: "100%",
                    padding: "10px",
                    marginTop: "5px",
                    cursor: "pointer",
                    width: "20px",
                    height: "20px",
                  }}
                  onClick={back5}
                />
              </div>
            </div>
            <div className='col-lg-8 container px-lg-5 px-3'>
              <div className='d-flex align-items-center h-100 w-100'>
                <div className='w-100'>
                  <div className='cross-button-position' onClick={handleClose6}>
                    <FontAwesomeIcon icon={faXmark} className='cross' />
                  </div>
                  <div className='LastModel'>
                    <div
                      className='txtLast'
                      style={{
                        fontSize: mobile ? "16px" : "20px",
                        fontWeight: "600",
                        textAlign: "center",
                      }}
                    >
                      User Details
                    </div>
                    <div>
                      <div className='mt-2 modal_6 text-white'>
                        {customerRequirmentToken &&
                          customerRequirmentToken.length > 0 ? (
                          <input
                            type='text'
                            name='full_name'
                            // onChange={txt}
                            placeholder='Full Name'
                            value={parsedData.details.full_name}
                            style={{
                              color: "white",
                            }}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^A-Za-z ]/g,
                                "",
                              )
                              txt(e)
                            }}
                          />
                        ) : (
                          <input
                            type='text'
                            name='full_name'
                            // onChange={txt}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^A-Za-z ]/g,
                                "",
                              )
                              txt(e)
                            }}
                            value={details.full_name}
                            placeholder='Full Name'
                            style={{
                              color: "white",
                            }}
                          />
                        )}
                      </div>
                      <div className='mt-4 modal_6'>
                        {customerRequirmentToken &&
                          customerRequirmentToken.length > 0 ? (
                          <input
                            type='text'
                            name='email_address'
                            onChange={txt}
                            placeholder='Full Name'
                            value={parsedData.details.email_address}
                            style={{
                              color: "white",
                            }}
                          />
                        ) : (
                          <input
                            type='email'
                            name='email_address'
                            value={details.email_address}
                            onChange={txt}
                            placeholder='Email'
                            style={{
                              color: "white",
                            }}
                          />
                        )}
                      </div>
                      <div className='mt-4 modal_6'>
                        {customerRequirmentToken &&
                          customerRequirmentToken.length > 0 ? (
                          <input
                            type='tel'
                            name='mobile_no'
                            onChange={txt}
                            placeholder='Mobile No'
                            onInput={(e) =>
                            (e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              "",
                            ))
                            }
                            value={parsedData.details.mobile_no}
                            style={{
                              color: "white",
                            }}
                            maxLength={10}
                          />
                        ) : (
                          <input
                            type='tel'
                            name='mobile_no'
                            value={details.mobile_no}
                            onChange={txt}
                            onInput={(e) =>
                            (e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              "",
                            ))
                            }
                            placeholder='Mobile No'
                            style={{
                              color: "white",
                            }}
                            maxLength={10}
                          />
                        )}
                      </div>
                      <div className='mt-4 modal_6'>
                        <select
                          name='state'
                          id=''
                          value={details.state}
                          onChange={handleStateChange}
                          style={{
                            color: "white",
                          }}
                        >
                          <option value='' style={{ color: "black" }} disabled>
                            Select State
                          </option>
                          {indiaCountry &&
                            indiaCountry.states.map((e) => {
                              return (
                                <>
                                  <option
                                    key={e._id}
                                    value={e.name}
                                    style={{ color: "black" }}
                                  >
                                    {e.name}
                                  </option>
                                </>
                              )
                            })}
                        </select>
                      </div>
                      <div className='mt-4 modal_6'>
                        <select
                          name='city'
                          id=''
                          value={details.city}
                          onChange={txt}
                          style={{
                            color: "white",
                          }}
                        >
                          <option value='' style={{ color: "black" }} disabled>
                            Select City
                          </option>
                          {citie &&
                            citie.cities.map((city, index) => (
                              <option
                                key={index}
                                value={city.name}
                                style={{ color: "black" }}
                              >
                                {city.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div>
                        <div className='mt-3 txtLast'>
                          <span
                            style={{
                              fontSize: "22px",
                              fontWeight: "400",
                              marginRight: "5px",
                            }}
                          >
                            Budget
                          </span>
                          <span>(minimum budget must be 5000)</span>
                        </div>
                        <div className='modal_6'>
                          {customerRequirmentToken &&
                            customerRequirmentToken.length > 0 ? (
                            <input
                              type='number'
                              name='budget_per_person'
                              onChange={txt}
                              value={parsedData.details.budget_per_person}
                              style={{
                                color: "white",
                              }}
                            />
                          ) : (
                            <>
                              <input
                                type='number'
                                name='budget_per_person'
                                value={details.budget_per_person}
                                onChange={txt}
                                style={{
                                  color: "white",
                                }}
                              />
                              <span style={{ color: "red" }}>{errors}</span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!details.full_name ||
                    !details.email_address ||
                    !details.mobile_no ||
                    !details.city ||
                    !details.budget_per_person ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {error}
                    </span>
                  ) : (
                    ""
                  )}

                  <div
                    className='py-2 w-75 ps-4 mx-4 mt-3'
                    onClick={CustomPackage}
                  >
                    <p
                      className='load_btn text-center'
                      style={{ cursor: "pointer" }}
                    >
                      Submit
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}

export default Practic
